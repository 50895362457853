import { isRecommendingRenewSubscription } from "@/commons/libs/utils/contractUtils";

import { Contract } from "@/commons/domain/models/contract";
import { Token } from "@/commons/domain/models/token";

import contents from "@/dashboard/contents/token-action";

export interface ContractCardActionItem {
  actionName: string;
  allowedActionName: string;
  label: string;
  isRecommendedPredicate?: (contract: Contract) => boolean;
  extraClass?: string;
  iconName?: string;
  isSensitiveAction?: boolean;
}

export const APIKEY_ACTION_ITEMS: ContractCardActionItem[] = [
  {
    allowedActionName: "token.extend",
    actionName: "extendToken",
    label: contents.extendApiKey,
    iconName: "TimeRead24",
  },
  {
    allowedActionName: "token.delete",
    actionName: "deleteToken",
    label: contents.deleteApiKey,
    extraClass: "contract-card-action-item--critical",
    isSensitiveAction: true,
    iconName: "PublishTrashbin24",
  },
];

export const SUBSCRIPTION_ACTION_ITEMS: ContractCardActionItem[] = [
  {
    allowedActionName: "contract.renew",
    actionName: "renewSubscription",
    label: contents.renewApiKey,
    iconName: "CustomCopyKey24",
    isRecommendedPredicate: (contract: Contract) =>
      isRecommendingRenewSubscription(contract),
  },
  {
    allowedActionName: "contract.docs",
    actionName: "openApiDocumentation",
    label: contents.apiDocumentation,
    iconName: "BookStore24",
  },
  {
    allowedActionName: "contract.contact",
    actionName: "contactApiOwners",
    label: contents.contactApiOwners,
    iconName: "ContactMail24",
  },
  {
    allowedActionName: "contract.manage",
    actionName: "manageApiSettings",
    label: contents.manageApiSettings,
    iconName: "API24",
  },
  {
    allowedActionName: "contract.delete",
    actionName: "unsubscribe",
    label: contents.unsubscribe,
    isSensitiveAction: true,
    extraClass: "contract-card-action-item--critical",
    iconName: "ControlCross24",
  },
];

const DEFAULT_SUBSCRIPTION_ACTIONS = [
  "openApiDocumentation",
  "contactApiOwners",
];

export const getSubscriptionActionsMenu = (contract: Contract) => {
  return SUBSCRIPTION_ACTION_ITEMS.filter(
    (item) =>
      DEFAULT_SUBSCRIPTION_ACTIONS.includes(item.actionName) ||
      contract?.allowedActions[item.allowedActionName] === true,
  );
};

export const getTokenActionsMenu = (token: Token) => {
  return APIKEY_ACTION_ITEMS.filter(
    (item) => token.allowedActions[item.allowedActionName] === true,
  );
};
