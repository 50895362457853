<template>
  <div
    v-if="form"
    :class="[
      'update-or-remove-routing-policy',
      { 'update-or-remove-routing-policy--enabled': isUpdating },
    ]"
  >
    <!-- Conditions -->
    <div v-if="!isUpdating">
      <div
        class="update-or-remove-routing-policy__wrapper-conditions--disabled"
      >
        <div class="update-or-remove-routing-policy__conditions--disabled">
          <div
            v-for="condition in form.conditions"
            :key="condition.id"
            class="update-or-remove-routing-policy__condition--disabled"
          >
            <div
              class="update-or-remove-routing-policy__condition-type--disabled"
            >
              {{ conditionsLabels(condition.type) }}
            </div>
            <div
              class="update-or-remove-routing-policy__condition-key--disabled"
            >
              {{ condition.key ? condition.key : "-" }}
            </div>
            <div
              class="update-or-remove-routing-policy__condition-value--disabled"
            >
              {{ getConditionValue(condition) }}
            </div>
          </div>
        </div>
        <div class="update-or-remove-routing-policy__url--disabled">
          {{ url }}
        </div>
        <div
          v-if="!isDisabled"
          class="update-or-remove-routing-policy__actions--disabled"
        >
          <!-- Edit and remove buttons -->
          <ActionButtonsWrapper>
            <ButtonEdit @click="edit" />
            <ButtonDelete @click="remove" />
          </ActionButtonsWrapper>
        </div>
      </div>
    </div>
    <div v-if="isUpdating" class="update-or-remove-routing-policy__conditions">
      <Conditions
        :value="form.conditions"
        :errors="form.errors"
        :routeEntryPoints="routeEntryPoints"
        @input="form.conditions = $event"
      />
    </div>
    <!-- TargetUrl -->
    <div v-if="isUpdating" class="update-or-remove-routing-policy__target-url">
      <Tooltip
        class="update-or-remove-routing-policy__target-url-tooltip"
        :tooltipContent="url"
      >
        <DvpField :errorMessage="urlValidationError">
          <MTextInput
            v-model="url"
            reference="inputTargetUrl"
            placeholder="Target URL"
            :isDisabled="!isUpdating"
            :isInvalid="urlValidationError != undefined"
          />
        </DvpField>
      </Tooltip>
    </div>
    <!-- Actions -->
    <div class="update-or-remove-routing-policy__actions">
      <template v-if="isUpdating">
        <!-- update and cancel buttons -->
        <span
          class="update-or-remove-routing-policy__action-cancel"
          @click="cancel"
        >
          <MIcon
            name="ControlCircleCross24"
            color="var(--color-danger)"
          ></MIcon>
        </span>
        <span
          class="update-or-remove-routing-policy__action-update"
          data-cy="save-dynamic-routing"
          @click="update"
        >
          <MIcon
            name="NotificationCircleAvailable24"
            color="var(--color-primary)"
          ></MIcon>
        </span>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { markRaw } from "vue";

import ActionButtonsWrapper from "@/commons/components/Buttons/ActionButtonsWrapper.vue";
import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import MessageConfirmModal from "@/commons/components/Modal/MessageConfirmModal.vue";
import Tooltip from "@/commons/components/Tooltip/Tooltip.vue";
import DvpField from "@/commons/components/form/DvpField.vue";

import Conditions from "./Conditions.vue";

import { debounce } from "@/commons/libs/utils/debouncer";
import { parseAndSortHttpMethods } from "@/commons/utils/http-method";

import { validateRouteTargetUrl } from "@/commons/services/api/api-service";
import { RoutingPolicyForm } from "@/manager/domain/forms/routing-policy-form";

import { ERoutingPolicyType } from "@/commons/store/types";

import contents from "@/manager/contents/update-or-remove-routing-policy";

export default {
  name: "UpdateOrRemoveRoutingPolicy",
  components: {
    ButtonDelete,
    ActionButtonsWrapper,
    ButtonEdit,
    Conditions,
    MIcon,
    MTextInput,
    Tooltip,
    DvpField,
  },
  props: {
    routingPolicy: {
      type: Object,
      required: true,
    },
    routeEntryPoints: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
    },
  },
  emits: [
    "update",
    "remove",
    "displayNotification",
    "stopDraggable",
    "allowDraggable",
  ],
  data() {
    return {
      isUpdating: false,
      persistedFormData: undefined,
      contents,
      form: RoutingPolicyForm.create(),
      urlValidationError: undefined,
    };
  },
  computed: {
    url: {
      get(): string {
        return this.form.url;
      },
      set(newUrl: string) {
        this.form.url = newUrl;
        this.debouncedValidateTargetUrl();
      },
    },
  },
  watch: {
    async isDisabled(isDisabled) {
      if (isDisabled) {
        await this.cancel();
      }
    },
  },
  created() {
    this.debouncedValidateTargetUrl = debounce(this.validateTargetUrl);
  },
  mounted() {
    const { url, conditions } = this.routingPolicy;
    this.form.init({ url, conditions });
  },
  methods: {
    async update() {
      await this.form.validate();
      await this.validateTargetUrl();

      if (!this.form.errors.length && this.urlValidationError == undefined) {
        const { url, conditions } = this.form.data();
        this.$emit("update", { ...this.routingPolicy, url, conditions });
        this.persistedFormData = this.form.data();
        this.isUpdating = false;
      }
      this.$emit("allowDraggable");
    },
    async cancel() {
      const { url, conditions } = this.routingPolicy;
      await this.form.init({ url, conditions });
      this.urlValidationError = undefined;
      this.isUpdating = false;
      this.$emit("allowDraggable");
    },
    async remove() {
      if (!this.isDisabled) {
        this.$store.commit("openModal", {
          title: this.contents.removeModalTitle,
          component: markRaw(MessageConfirmModal),
          props: {
            title: this.contents.confirmRemoveMessage,
          },
          listeners: {
            onSubmit: () => {
              this.$emit("remove", this.routingPolicy.id);
              this.$store.commit("closeModal");
            },
          },
        });
      }
    },
    getConditionValue(condition) {
      switch (condition.type) {
        case ERoutingPolicyType.ORIGIN:
          return this.originLabel(condition.value);
        case ERoutingPolicyType.HTTP_METHODS:
          return parseAndSortHttpMethods(condition.value).join(", ");
        default:
          return condition.value;
      }
    },
    originLabel(routeId) {
      const route = this.routeEntryPoints.find((route) => route.id === routeId);
      return route?.label;
    },
    edit() {
      if (!this.isDisabled) {
        this.persistedFormData = this.form.data();
        this.isUpdating = true;
        this.$emit("stopDraggable");
      }
      this.isUpdating = true;
      this.$emit("displayNotification");
    },
    conditionsLabels(conditionType) {
      return [
        {
          label: "Header",
          value: "headers",
        },
        { label: "Method", value: "httpMethods" },
        { label: "Path", value: "path" },
        { label: "Origin", value: "origin" },
      ].find((condition) => condition.value === conditionType)?.label;
    },
    async validateTargetUrl() {
      const validation = await validateRouteTargetUrl(this.form.url);
      this.urlValidationError = validation.message;
    },
  },
};
</script>

<style lang="scss">
.update-or-remove-routing-policy--enabled {
  display: flex;
  padding: 1rem 0;
}
.update-or-remove-routing-policy {
  width: 100%;
  border-bottom: 2px solid var(--color-light-grey);

  &__conditions {
    width: 60%;
  }
  &__target-url {
    width: 30%;
    padding-right: 1rem;
  }
  &__actions {
    width: 10%;
    margin-top: 0.5rem;
  }
}

.update-or-remove-routing-policy__target-url-tooltip {
  width: 100%;
}

.update-or-remove-routing-policy__url--disabled {
  width: 30%;
  padding: 10px 0;
  margin-right: 1rem;
  word-break: break-all;
}

.update-or-remove-routing-policy__actions--disabled {
  width: 10%;
  padding: 10px 0;
}

.update-or-remove-routing-policy__wrapper-conditions--disabled {
  display: flex;
}

.update-or-remove-routing-policy__conditions--disabled {
  width: 60%;
}

.update-or-remove-routing-policy__condition-type--disabled,
.update-or-remove-routing-policy__condition-key--disabled {
  width: 25%;
}
.update-or-remove-routing-policy__condition-value--disabled {
  width: 50%;
  word-break: break-all;
}

.update-or-remove-routing-policy__condition--disabled {
  display: flex;
  width: 100%;
  padding: 10px 0;
}
.update-or-remove-routing-policy__action-cancel,
.update-or-remove-routing-policy__action-update,
.update-or-remove-routing-policy__action-edit,
.update-or-remove-routing-policy__action-remove {
  margin-right: 0.5rem;
  cursor: pointer;
}
</style>
