<template>
  <div id="app">
    <RouterView />
    <CookieBanner @cookieIsReady="onCookieIsReady" />
    <GlobalLayerModal />
    <GlobalModal />
    <ToastNotifications horizontalPosition="right" verticalPosition="bottom" />
  </div>
</template>

<script lang="ts">
import { startDatadogRecording } from "@/datadog";

import CookieBanner from "@/commons/components/CookieBanner/CookieBanner.vue";
import {
  getCookie,
  removeUnnecessaryCookies,
  statisticIsAccepted,
} from "@/commons/components/CookieBanner/cookies-utils";
import GlobalLayerModal from "@/commons/components/Modal/GlobalLayerModal.vue";
import GlobalModal from "@/commons/components/Modal/GlobalModal.vue";
import ToastNotifications from "@/commons/components/Notifications/ToastNotifications.vue";

import { addDatadomeScriptElement } from "@/commons/utils/datadome.utils";

export default {
  components: {
    CookieBanner,
    GlobalLayerModal,
    GlobalModal,
    ToastNotifications,
  },
  computed: {
    isLogged() {
      return this.$store.getters["userIsLogged"];
    },
    userHasAcceptedTOS() {
      return this.$store.getters["userHasAcceptedTOS"];
    },
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    styles() {
      return this.$store.getters["config/styles"];
    },
  },
  watch: {
    $route() {
      if (
        this.frontendConfig.customStylePages.indexOf(
          document.location.pathname,
        ) > -1
      ) {
        if (this.styles.colors) {
          (Object.values(document.styleSheets) as any[]).forEach(
            (stylesheet) => {
              // There is a security Error when trying to access stylesheet coming from external domain (css file for google fonts for example)
              // Our own stylesheets seem to all have href==null so we identify them with this attribute
              const stylesheetIsFromLocalDomain = stylesheet.href == null;
              if (stylesheetIsFromLocalDomain) {
                Object.values(stylesheet.cssRules as any[]).forEach((rule) => {
                  if (rule.style) {
                    for (let prop of rule.style) {
                      if (rule.style[prop]) {
                        Object.keys(this.styles.colors).forEach((c) => {
                          if (rule.style[prop].includes(c)) {
                            rule.style[prop] = this.styles.colors[c];
                          }
                        });
                      }
                    }
                  }
                });
              }
            },
          );
        }
      }
    },
    isLogged: {
      immediate: true,
      async handler() {
        if (this.isLogged) {
          await this.loadGlobalStoreData();
        }
      },
    },
  },
  async mounted() {
    addDatadomeScriptElement();
    this.startPingCookieWatcher();
    await this.loadAnonymousStoreData();
  },
  methods: {
    async onCookieIsReady() {
      if (statisticIsAccepted()) {
        try {
          startDatadogRecording(this.$store.getters["config/frontendConfig"]);
        } catch (error) {
          console.error(error);
        }
      }
      removeUnnecessaryCookies();
    },
    async loadGlobalStoreData() {
      await this.$store.dispatch("config/loadInitConfig");
      await this.$store.dispatch("loadZones");
      await this.$store.dispatch("loadTags");
    },
    async loadAnonymousStoreData() {
      await this.$store.dispatch("config/loadEnvironmentsConfig");
    },
    startPingCookieWatcher() {
      let cookies = document.cookie;
      let isPing = false;
      setInterval(async () => {
        if (document.cookie !== cookies) {
          cookies = document.cookie;
          if (getCookie("PING")) {
            isPing = true;
          }
          if (this.isLogged && isPing && !getCookie("PING")) {
            isPing = false;
            await this.$store.dispatch("logout");
            await this.$router.push({
              path: `/login?redirect=${this.$router.currentRoute.fullPath}`,
            });
          }
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/external-libs";
@import "~@/commons/assets/styles/main";
</style>
