<template>
  <Stack class="api-alerts">
    <MNotification
      v-for="(alert, index) in checkList"
      :key="`alert-message-${index}`"
      :title="alert.label"
      :type="alert.alertType"
    >
      {{ messagePreLink(alert) }}
      <MLink
        v-if="hasLink(alert)"
        size="s"
        :href="convertRouteToHref(alert.link.route)"
      >
        {{ alert.link.label }}
      </MLink>
      {{ messagePostLink(alert) }}
    </MNotification>
  </Stack>
</template>

<script setup lang="ts">
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { PropType } from "vue";

import Stack from "@/commons/components/Stack.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import { ApiCheck } from "@/manager/store/types";

defineProps({
  checkList: {
    type: Array as PropType<ApiCheck[]>,
    default: () => [],
  },
});

const messagePreLink = (alert: ApiCheck) => {
  if (hasLink(alert)) {
    return alert.message.split(alert.link.label)[0];
  }
  return alert.message;
};

const messagePostLink = (alert: ApiCheck) => {
  if (hasLink(alert)) {
    return alert.message.split(alert.link.label)[1];
  }
  return "";
};

const hasLink = (alert: ApiCheck) => {
  return alert.link && alert.link.label && alert.link.route;
};
</script>
