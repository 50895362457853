<script setup lang="ts">
import DvpButton from "@/commons/components/Action/DvpButton.vue";
import ActionZone from "@/commons/components/ActionZone.vue";

import contents from "@/manager/contents/create-target-url";

const emit = defineEmits(["click"]);
</script>

<template>
  <ActionZone class="button-add-route">
    <DvpButton
      data-cy="add-route"
      :label="contents.buttonAdd"
      icon="ControlCircleMore24"
      @click="emit('click')"
    ></DvpButton>
  </ActionZone>
</template>

<style lang="scss">
.button-add-route {
  width: 100%;
}
</style>
