<template>
  <div v-if="!isLoading" class="create-api-first-step">
    <CreateGroup v-if="currentUserGroupsList.length === 0" />
    <template v-else>
      <DvpStepper :steps="stepperSteps" :stepperDescription="contents.title" />
      <div
        v-if="userHadNoGroupOnModalOpen"
        class="create-api-first-step__group-creation-notification"
      >
        <MIcon
          class="create-api-first-step__group-creation-notification-icon"
          name="NotificationCircleAvailable24"
          color="var(--color-status-success)"
        />
        <span>{{ contents.groupCreationSuccessNotification }}</span>
      </div>
      <div class="create-api-first-step__form-wrapper">
        <LayerModalForm
          class="create-api-first-step__form"
          data-cy="create-api-form"
          @submit="submit"
        >
          <template #fields>
            <DvpField
              class="create-api-first-step__related-product-field"
              required
              :label="contents.relatedProduct"
              :helpText="contents.relatedProductHelpText"
              :errorMessage="form.firstError('product')"
              data-cy="related-product-field"
            >
              <MAutocomplete
                v-model="form.productId"
                :items="productsList"
                :filter="false"
                dataValueExpr="id"
                dataTextExpr="name"
                :disabled="isLoading"
                :placeholder="contents.relatedProductPlaceholder"
                @update:input="debouncedSearchProduct"
              />
            </DvpField>

            <DvpField
              class="create-api-first-step__business-unit-field"
              required
              :label="contents.businessUnit"
              :helpText="contents.businessUnitHelpText"
              :errorMessage="form.firstError('businessUnitTagIds')"
              data-cy="business-unit-field"
            >
              <MAutocomplete
                v-model="form.businessUnitTagIds"
                :items="buTagsList"
                dataValueExpr="id"
                dataTextExpr="label"
                :disabled="isLoading"
                :placeholder="contents.businessUnitPlaceholder"
                multiple
                tagList
              />
            </DvpField>

            <DvpField
              class="create-api-first-step__user-group-field"
              required
              :label="contents.managerGroup"
              :helpText="contents.managerGroupHelpText"
              :errorMessage="form.firstError('groupId')"
              data-cy="user-group-field"
            >
              <GroupAutocompleteWrapper
                v-model="form.groupId"
                :items="userGroups"
                dataValueExpr="id"
                dataTextExpr="name"
                :filter="false"
                :placeholder="contents.managerGroupPlaceholder"
                @update:input="debouncedSearchUserGroups"
                @update:model-value="onGroupSelection"
              />
            </DvpField>

            <LinkManageGroups class="create-api-first-step__link-groups" />

            <NotificationWarningMCCGroup v-if="displayMCCWarning" />
          </template>
          <template #formFooter>
            <MButton
              theme="bordered-neutral"
              :label="contents.cancel"
              @click="$emit('onClose')"
            />
            <MButton
              class="create-api-first-step__submit-button"
              type="submit"
              :label="contents.next"
              :disabled="!canSubmit"
            />
          </template>
        </LayerModalForm>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import MAutocomplete from "@mozaic-ds/vue-3/src/components/autocomplete/MAutocomplete.vue";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { markRaw } from "vue";

import CreateGroup from "@/commons/components/CreateGroup.vue";
import DvpStepper from "@/commons/components/DvpStepper.vue";
import LinkManageGroups from "@/commons/components/ManageGroups/LinkManageGroups.vue";
import NotificationWarningMCCGroup from "@/commons/components/ManageGroups/NotificationWarningMCCGroup.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";
import GroupAutocompleteWrapper from "@/commons/components/form/SearchGroupAutocompleteWrapper.vue";

import CreateApiSecondStep from "./CreateApiSecondStep.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import { getOrderedBuTagsList } from "@/commons/utils/filter-utils";
import {
  isLocalGroup,
  isOnlyInternalsGroup,
} from "@/commons/utils/group-utils";

import { GroupWithUsersAcls } from "@/commons/domain/models/group-with-users-acls";
import { searchUserGroupsApiCreation } from "@/commons/services/group/group-service";
import {
  getProductById,
  searchProduct,
} from "@/commons/services/product/product-service";
import { ApiForm } from "@/manager/domain/forms/api-form";

import contents from "@/manager/contents/create-api";

export default {
  components: {
    NotificationWarningMCCGroup,
    LinkManageGroups,
    MAutocomplete,
    GroupAutocompleteWrapper,
    LayerModalForm,
    DvpField,
    MButton,
    MIcon,
    CreateGroup,
    DvpStepper,
  },
  props: {
    initialFormData: {
      type: Object,
      default: undefined,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      form: ApiForm.create("FIRST_STEP"),
      debouncer: new Debouncer(),
      productsList: [],
      userHadNoGroupOnModalOpen: false as Boolean,
      isLoading: true as Boolean,
      userGroups: [] as GroupWithUsersAcls[],
      displayMCCWarning: false as Boolean,
    };
  },
  computed: {
    stepperSteps() {
      return [
        { label: contents.fillGlobalInformationStepLabel, isCurrent: true },
        { label: contents.fillApiInformationStepLabel },
      ];
    },
    currentUserGroupsList() {
      return this.$store.getters["currentUserGroupsList"];
    },
    userIsInternal(): boolean {
      return this.$store.getters["userIsInternal"];
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    buTagsList() {
      let businessUnits = getOrderedBuTagsList(
        this.$store.getters["buTagsList"],
      );

      return businessUnits.map(({ id, label, description }) => {
        return description
          ? { id, label: `${label} - ${description}` }
          : { id, label };
      });
    },
    hasErrorOnAnyFirstStepFields(): boolean {
      return (
        this.form.hasError("businessUnitTagIds", true) ||
        this.form.hasError("productId", true) ||
        this.form.hasError("groupId", true)
      );
    },
    canSubmit(): boolean {
      return !this.hasErrorOnAnyFirstStepFields && !this.isSaving;
    },
  },
  async mounted() {
    this.isLoading = true;

    this.userHadNoGroupOnModalOpen = this.currentUserGroupsList.length === 0;
    await this.searchProducts();
    await this.searchUserGroups();

    if (!this.initialFormData) {
      await this.form.init();
    } else {
      const { businessUnitTagIds, productId, groupId } = this.initialFormData;
      await this.form.init({ businessUnitTagIds, productId, groupId });
      if (productId != undefined) {
        await this.searchSelectedProduct(productId);
      }
    }

    this.isLoading = false;
  },
  methods: {
    isInvalidField(fieldName: string): boolean {
      return this.form.firstError(fieldName) != null;
    },
    async submit() {
      this.form.validate();

      if (!this.isSaving && !this.form.errors.length) {
        const { businessUnitTagIds, productId, groupId } = this.form.data();

        this.$store.commit("openLayerModal", {
          title: this.contents.title,
          component: markRaw(CreateApiSecondStep),
          props: {
            initialFormData: {
              ...this.initialFormData, // initialFormData could have step 2 fields if we came here via the 'previous' btn
              businessUnitTagIds,
              productId,
              groupId,
            },
            shouldShowFormErrorsOnInit: this.initialFormData != undefined,
          },
        });
      }
    },
    debouncedSearchProduct(searchValue: string): void {
      this.debouncer.debounce(() => this.searchProducts(searchValue), 100);
    },
    async searchProducts(search = "") {
      this.productsList = await searchProduct(search);
    },
    async searchSelectedProduct(productId: string) {
      const selectedProduct = await getProductById(productId);
      if (
        this.productsList.find((product) => product.id === productId) == null
      ) {
        this.productsList.push(selectedProduct);
      }
    },
    debouncedSearchUserGroups(searchValue: string): void {
      this.debouncer.debounce(() => this.searchUserGroups(searchValue), 100);
    },
    async searchUserGroups(search = "") {
      this.userGroups = await searchUserGroupsApiCreation(
        search,
        this.form.productId,
      );
    },
    onGroupSelection(groupId: string) {
      const selectedGroup = this.userGroups.find(
        (g: GroupWithUsersAcls) => g.id === groupId,
      );
      this.displayMCCWarning =
        selectedGroup != undefined &&
        this.userIsInternal &&
        isLocalGroup(selectedGroup) &&
        isOnlyInternalsGroup(selectedGroup);
    },
  },
};
</script>

<style lang="scss">
.create-api-first-step {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;
}

.create-api-first-step__form-wrapper {
  flex: 1;
}

.create-api-first-step__link-groups {
  align-self: flex-start;
}

.create-api-first-step__group-creation-notification {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--color-stroke-light);
}

.create-api-first-step__group-creation-notification-icon {
  margin-right: 0.5rem;
}
</style>
