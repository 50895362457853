<template>
  <div class="create-target-url">
    <DvpNotification v-if="showNoAvailableZonesNotification" type="information">
      {{ contents.noGatewaysAvailable }}
    </DvpNotification>

    <CardContainer v-else-if="showCreationForm">
      <form
        class="create-target-url__container"
        autocomplete="off"
        @submit.prevent
      >
        <h4>{{ contents.header }}</h4>

        <DvpField
          data-cy="create-url-endpoint"
          :label="contents.targetUrl"
          :helpText="contents.targetUrlTooltip"
          :errorMessage="urlValidationError"
          :isInvalid="urlValidationError != undefined"
          required
        >
          <DvpTextInput
            v-model="form.url"
            :placeholder="contents.targetUrlPlaceholder"
            :isInvalid="urlValidationError != undefined"
            @update:modelValue="onUrlChange"
          />
        </DvpField>

        <DvpField
          data-cy="select-zones"
          :label="contents.gateways"
          :helpText="contents.gatewaysTooltip"
          :isInvalid="form.hasError('zoneIds')"
          required
        >
          <SelectZones :form="form" />
        </DvpField>

        <DynamicRouting
          :routingPolicies="routingPolicies"
          @update="updateRoutingPolicies"
        />

        <div class="create-target-url__actions">
          <DvpButton
            theme="bordered-neutral"
            :label="contents.buttonCancelLabel"
            @click="cancel"
          />
          <DvpButton
            data-cy="save-the-route"
            :label="contents.buttonSaveLabel"
            @click="create"
          />
        </div>
      </form>
    </CardContainer>

    <ButtonAddRoute v-else @click="edit" />
  </div>
</template>

<script lang="ts">
import DvpButton from "@/commons/components/Action/DvpButton.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import DvpNotification from "@/commons/components/Indicator/DvpNotification.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import DvpTextInput from "@/commons/components/form/DvpTextInput.vue";
import ButtonAddRoute from "@/manager/views/ApiDetailRouting/ButtonAddRoute.vue";

import DynamicRouting from "./DynamicRouting.vue";
import SelectZones from "./SelectZones.vue";

import { debounce } from "@/commons/libs/utils/debouncer";

import { validateRouteTargetUrl } from "@/commons/services/api/api-service";

import contents from "@/manager/contents/create-target-url";

export default {
  components: {
    DvpTextInput,
    DvpField,
    ButtonAddRoute,
    CardContainer,
    DvpNotification,
    SelectZones,
    DynamicRouting,
    DvpButton,
  },
  props: {
    formParent: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
      isEditing: false,
      routingPolicies: [],
      urlValidationError: undefined,
    };
  },
  computed: {
    showCreationForm() {
      return this.isEditing;
    },
    availableZones() {
      return Object.values(this.$store.getters["availableZones"]);
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    showNoAvailableZonesNotification(): boolean {
      return !this.isLoading && !this.isEditing && !this.availableZones.length;
    },
  },
  created() {
    this.debouncedValidateTargetUrl = debounce(this.validateTargetUrl);
  },
  async mounted() {
    await this.form.init();
  },
  methods: {
    async create() {
      await this.form.validate();
      await this.formParent.validate();
      await this.validateTargetUrl();

      if (
        !this.form.errors.length &&
        !this.formParent.errors.length &&
        this.urlValidationError == undefined
      ) {
        const route = this.form.data();

        this.$store.dispatch("createRoute", {
          apiId: this.currentApi.id,
          route,
          routingPolicies: this.routingPolicies,
        });

        this.isEditing = false;
      }
    },
    async cancel() {
      this.routingPolicies = [];
      await this.formParent.validate();
      this.isEditing = false;
    },
    async edit() {
      this.form.clear();
      this.urlValidationError = undefined;
      this.isEditing = true;
    },
    updateRoutingPolicies(updatedRoutingPolicies) {
      this.routingPolicies = updatedRoutingPolicies;
    },
    async onUrlChange(newUrl) {
      await this.form.update({ url: newUrl });
      this.debouncedValidateTargetUrl();
    },
    async validateTargetUrl() {
      const validation = await validateRouteTargetUrl(this.form.url);
      this.urlValidationError = validation.message;
    },
  },
};
</script>

<style lang="scss">
.create-target-url__container {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}

.create-target-url__actions {
  display: flex;
  justify-content: flex-end;

  gap: 1rem;
}
</style>
