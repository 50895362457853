export default {
  actions: "Actions",
  addDynamicRoute: "Add dynamic route",
  conditions: "Conditions",
  key: "Key",
  value: "Value",
  dynamicRouting: "Dynamic routing",
  message:
    "Headers that have already been defined on the client side will not be overridden by the gateway.",
  headerName: "Header key",
  headerValue: "Header value",
  headerOverride: "Override",
  headerActions: "Actions",
  order: "Order",
  overrideTooltip:
    "You can override headers that have already been defined on the client side by using toggle on this column",
  targetUrl: "Target url",
  notificationTitle:
    "Your dynamic routing is not still saved. Do not forget to save the route, at the top of this form.",
};
