import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "update-or-remove-target-url__header-bar" }
const _hoisted_2 = { class: "update-or-remove-target-url__header" }
const _hoisted_3 = {
  key: 1,
  "data-cy": "target-url"
}
const _hoisted_4 = {
  key: 1,
  class: "update-or-remove-target-url__actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DvpButton = _resolveComponent("DvpButton")!
  const _component_DvpTextInput = _resolveComponent("DvpTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_DvpNotification = _resolveComponent("DvpNotification")!
  const _component_SelectZones = _resolveComponent("SelectZones")!
  const _component_DynamicRouting = _resolveComponent("DynamicRouting")!
  const _component_DvpTooltip = _resolveComponent("DvpTooltip")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return ($props.form)
    ? (_openBlock(), _createBlock(_component_CardContainer, {
        key: 0,
        disabled: $props.isDisabled
      }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            class: "update-or-remove-target-url",
            autocomplete: "off",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h4", null, _toDisplayString($data.contents.header) + " #" + _toDisplayString($props.positionInList), 1)
              ]),
              (!$data.isEditing)
                ? (_openBlock(), _createBlock(_component_DvpButton, {
                    key: 0,
                    "data-cy": "edit-route",
                    theme: "solid-neutral",
                    label: $data.contents.buttonEditLabel,
                    onClick: $options.edit
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_DvpField, {
              label: $data.contents.targetUrl,
              helpText: $data.isEditing ? $data.contents.targetUrlTooltip : undefined,
              errorMessage: $data.urlValidationError,
              isInvalid: $data.urlValidationError != undefined,
              required: $data.isEditing
            }, {
              default: _withCtx(() => [
                ($data.isEditing)
                  ? (_openBlock(), _createBlock(_component_DvpTextInput, {
                      key: 0,
                      modelValue: $options.url,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.url) = $event)),
                      "data-cy": "edit-target-url",
                      placeholder: $data.contents.targetUrlPlaceholder,
                      isInvalid: $data.urlValidationError != undefined
                    }, null, 8, ["modelValue", "placeholder", "isInvalid"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($options.url), 1))
              ]),
              _: 1
            }, 8, ["label", "helpText", "errorMessage", "isInvalid", "required"]),
            ($data.isEditing)
              ? (_openBlock(), _createBlock(_component_DvpNotification, {
                  key: 0,
                  class: "update-or-remove-target-url__alert-message-gateway",
                  type: "information"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.contents.infoAskAnotherGateway), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_DvpField, {
              "data-cy": "select-zones",
              label: $data.contents.gateways,
              helpText: $data.isEditing ? $data.contents.gatewaysTooltip : undefined,
              isInvalid: $props.form.hasError('zoneIds'),
              required: $data.isEditing
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SelectZones, {
                  form: $props.form,
                  isDisabled: !$data.isEditing
                }, null, 8, ["form", "isDisabled"])
              ]),
              _: 1
            }, 8, ["label", "helpText", "isInvalid", "required"]),
            _createVNode(_component_DynamicRouting, {
              "data-cy": "dynamic-routing",
              routingPolicies: $data.currentlyUpdatingRoutingPolicies,
              routeEntryPoints: $options.downstreamEntryPoints,
              isDisabled: !$data.isEditing,
              isDisplayDynRoutingNotification: $data.isDisplayDynRoutingNotification,
              onUpdate: $options.updateRoutingPolicies,
              onDisplayNotification: $options.displayDynRoutingNotification
            }, null, 8, ["routingPolicies", "routeEntryPoints", "isDisabled", "isDisplayDynRoutingNotification", "onUpdate", "onDisplayNotification"]),
            (!$props.isDisabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  ($data.isEditing)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_DvpTooltip, {
                          disabled: !$options.existEnabledDownstreamRoutes,
                          text: $data.contents.unpublishedTooltip
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DvpButton, {
                              theme: "bordered-danger",
                              disabled: $options.existEnabledDownstreamRoutes,
                              label: $data.contents.buttonRemoveLabel,
                              onClick: $options.remove
                            }, null, 8, ["disabled", "label", "onClick"])
                          ]),
                          _: 1
                        }, 8, ["disabled", "text"]),
                        _createVNode(_component_DvpButton, {
                          theme: "bordered-neutral",
                          label: $data.contents.buttonCancelLabel,
                          onClick: $options.cancel
                        }, null, 8, ["label", "onClick"]),
                        _createVNode(_component_DvpButton, {
                          "data-cy": "save-the-route",
                          label: $data.contents.buttonUpdateLabel,
                          onClick: $options.update
                        }, null, 8, ["label", "onClick"])
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 32)
        ]),
        _: 1
      }, 8, ["disabled"]))
    : _createCommentVNode("", true)
}