<template>
  <div v-if="!isLoading" class="documentation-api">
    <div class="documentation-api__header">
      <h3>{{ contents.documentation }}</h3>
    </div>
    <CustomNotification
      v-if="haveDocWithoutUrl"
      :title="contents.urlMethod"
      iconName="PromiseCertification24"
      :description="contents.switchToUrl"
      documentationAnchor="#add-documentation"
    />
    <CardContainer>
      <MDataTable
        v-if="pagesList.length > 0"
        class="documentation-api__pages"
        :headers="tableHeaders"
        :source="pagesList"
        :sorting="{ mode: 'none' }"
      >
        <template #[`item.order`]="{ item, rowIndex }">
          <IconButton
            :disabled="isFirstItem(rowIndex)"
            mozaicIconName="ArrowArrowTop24"
            @click="moveUp(item, rowIndex)"
          />
          <IconButton
            :disabled="isLastItem(rowIndex)"
            mozaicIconName="ArrowArrowBottom24"
            @click="moveDown(item, rowIndex)"
          />
        </template>
        <template #[`item.name`]="{ item }">
          <div>{{ item.title }}</div>
        </template>
        <template #[`item.type`]="{ item }">
          <div>{{ getTypeLabel(item.type) }}</div>
        </template>
        <template #[`item.source`]="{ item }">
          <span class="documentation-api__page-source">
            {{ getPageSource(item) }}

            <DvpTooltip
              v-if="getPageSource(item) !== EDocumentationPageSource.URL"
              :text="contents.preferUrlMethod"
            >
              <MIcon
                name="NotificationWarning24"
                color="var(--color-status-warning)"
              />
            </DvpTooltip>

            <template
              v-if="getPageSource(item) === EDocumentationPageSource.URL"
            >
              <DvpTooltip
                v-if="isWorkerPending(item)"
                class="documentation-api__page-source-status"
                :text="contents.pendingFetch"
              >
                <img
                  :src="autoRefreshIcon"
                  class="documentation-api__status-pending-icon"
                />
              </DvpTooltip>

              <DvpTooltip
                v-else-if="isWorkerError(item)"
                class="documentation-api__page-source-status"
                :text="getPageError(item)"
              >
                <MIcon
                  name="NotificationWarning24"
                  color="var(--color-danger)"
                />
              </DvpTooltip>

              <DvpTooltip
                v-else
                class="documentation-api__page-source-status"
                :text="contents.fetchSuccess"
              >
                <MIcon
                  name="NotificationCircleAvailable24"
                  color="var(--color-primary)"
                />
              </DvpTooltip>
            </template>
          </span>
        </template>
        <template #[`item.lastUpdate`]="{ item }">
          <div class="documentation-api__page-lastupdate">
            {{ formatDate(item.updatedAt) }}
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="documentation-api__actions">
            <DvpTooltip
              :text="publishingToggleError(item)"
              :disabled="canTogglePublish(item)"
            >
              <DvpToggle
                :label="getPublishStatus(item.isPublished)"
                :checked="item.isPublished"
                :disabled="!canTogglePublish(item)"
                @click="togglePublish(item)"
              />
            </DvpTooltip>
            <ActionButtonsWrapper>
              <IconButton
                mozaicIconName="DisplayRefresh24"
                :disabled="!hasUrl(item) || isWorkerPending(item)"
                @click="refreshPage(item)"
              />
              <ButtonEdit @click="editApiDocumentationPage(item)" />
              <ButtonDelete
                :disabled="!canUnpublish(item)"
                @click="removePage(item)"
              />
            </ActionButtonsWrapper>
          </div>
        </template>
      </MDataTable>
      <ActionZone>
        <DvpTooltip
          :text="contents.reachedDocumentationLimitTooltip"
          :disabled="!hasReachedDocumentationLimit"
        >
          <MButton
            class="action-zone__button"
            :label="contents.addDocumentation"
            size="l"
            icon="ControlCircleMore24"
            :disabled="hasReachedDocumentationLimit"
            @click="addApiDocumentation()"
          />
        </DvpTooltip>
      </ActionZone>
    </CardContainer>
  </div>
</template>

<script lang="ts">
import { MAX_PAGE_COUNT } from "@/documentation/store/constants";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MDataTable from "@mozaic-ds/vue-3/src/components/datatable/MDataTable.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import moment from "moment";
import { markRaw } from "vue";

import ActionZone from "@/commons/components/ActionZone.vue";
import ActionButtonsWrapper from "@/commons/components/Buttons/ActionButtonsWrapper.vue";
import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import ButtonEdit from "@/commons/components/Buttons/ButtonEdit.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import DvpToggle from "@/commons/components/DvpToggle.vue";
import DvpTooltip from "@/commons/components/DvpTooltip.vue";
import IconButton from "@/commons/components/IconButton.vue";
import MessageConfirmModal from "@/commons/components/Modal/MessageConfirmModal.vue";
import CustomNotification from "@/commons/components/Notifications/CustomNotification.vue";
import AddOrUpdateDocumentationSecondStep from "@/manager/views/ApiDetailDocumentation/AddOrUpdateDocumentationSecondStep.vue";

import AddDocumentationFirstStep from "./AddDocumentationFirstStep.vue";

import {
  DocumentationPage,
  EDocumentationJobStatus,
  EDocumentationPageSource,
} from "@/commons/domain/models/documentation-page";

import contents from "@/manager/contents/documentation-api";
import { getTypeLabel } from "@/manager/contents/documentation-type-items";

export default {
  name: "DocumentationApi",
  components: {
    ActionButtonsWrapper,
    ButtonDelete,
    ButtonEdit,
    CustomNotification,
    IconButton,
    ActionZone,
    CardContainer,
    DvpToggle,
    DvpTooltip,
    MButton,
    MIcon,
    MDataTable,
  },
  data() {
    return {
      contents,
      showTooltip: false,
      EDocumentationPageSource,
      autoRefreshIcon: require("@/commons/assets/icons/icon-auto-refresh.svg"),
      pandaNotificationImg: require("@/commons/assets/images/panda-notification-icon.png"),
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    pagesList(): DocumentationPage[] {
      return this.$store.getters["managerDocumentationPagesList"];
    },
    hasContent() {
      return this.pagesList.length !== 0;
    },
    haveDocWithoutUrl() {
      return this.pagesList.some((page) => page.url === "");
    },
    hasReachedDocumentationLimit() {
      return this.pagesList.length == MAX_PAGE_COUNT;
    },
    tableHeaders() {
      return [
        {
          caption: this.contents.tableHeaderOrder,
          dataFieldExpr: "order",
        },
        {
          caption: this.contents.tableHeaderName,
          dataFieldExpr: "name",
        },
        {
          caption: this.contents.tableHeaderType,
          dataFieldExpr: "type",
        },
        {
          caption: this.contents.tableHeaderSource,
          dataFieldExpr: "source",
        },
        {
          caption: this.contents.tableHeaderLastUpdate,
          dataFieldExpr: "lastUpdate",
        },
        {
          caption: this.contents.tableHeaderActions,
          dataFieldExpr: "actions",
        },
      ];
    },
  },
  async mounted() {
    await this.$store.dispatch("getDocumentationPages", this.currentApi.id);
  },
  methods: {
    isFirstItem(rowIndex: number): boolean {
      return rowIndex === 0;
    },
    isLastItem(rowIndex: number): boolean {
      return rowIndex === this.pagesList.length - 1;
    },
    async moveUp(item: DocumentationPage, index: number) {
      if (this.isFirstItem(index)) return;

      await this.$store.dispatch("switchDocumentationPageOrder", {
        pageToSwitchId: this.pagesList[index - 1].id,
        draggedPageId: item.id,
      });
    },
    async moveDown(item: DocumentationPage, index: number) {
      if (this.isLastItem(index)) return;

      await this.$store.dispatch("switchDocumentationPageOrder", {
        pageToSwitchId: this.pagesList[index + 1].id,
        draggedPageId: item.id,
      });
    },
    async removePage(page) {
      this.$store.commit("openModal", {
        title: this.contents.removePageModal,
        component: markRaw(MessageConfirmModal),
        props: {
          title: this.contents.removePageModalTitle(page.title),
        },
        listeners: {
          onSubmit: () => {
            this.$store.dispatch("removeDocumentationPage", page.id);
          },
        },
      });
    },
    togglePublish(page) {
      this.$store.dispatch("updateDocumentationPage", {
        pageId: page.id,
        isPublished: !page.isPublished,
      });
    },
    addApiDocumentation() {
      this.$store.commit("openLayerModal", {
        title: "Add documentation 1/2",
        component: markRaw(AddDocumentationFirstStep),
      });
    },
    editApiDocumentationPage({ type, id }) {
      this.$store.commit("openLayerModal", {
        title: `Edit ${getTypeLabel(type).toLowerCase()}`,
        component: markRaw(AddOrUpdateDocumentationSecondStep),
        props: { pageId: id },
      });
    },
    hasUrl(page) {
      return !!page.url;
    },
    async refreshPage(page) {
      await this.$store.dispatch("refreshDocumentationPage", {
        pageId: page.id,
      });
    },
    formatDate(updatedAt) {
      return updatedAt != "" && updatedAt != null
        ? moment(updatedAt).format("YYYY/MM/DD HH:mm")
        : "--";
    },
    getPageSource(page) {
      return page.url
        ? EDocumentationPageSource.URL
        : EDocumentationPageSource.CONTENT;
    },
    isWorkerPending(page) {
      return page.lastJobStatus === EDocumentationJobStatus.PENDING;
    },
    isWorkerError(page) {
      return this.getPageError(page) !== undefined;
    },
    canPublish(page) {
      return page.content !== undefined;
    },
    canUnpublish(page) {
      return page.allowedActions["page.can-unpublish"];
    },
    canTogglePublish(page) {
      return page.isPublished ? this.canUnpublish(page) : this.canPublish(page);
    },
    publishingToggleError(page) {
      return this.canUnpublish(page)
        ? this.contents.cannotPublishInvalidContent
        : this.contents.cannotUnpublishDocumentationOfPublishedApi;
    },
    getPageError(page): string | undefined {
      if (page.lastJobStatus === EDocumentationJobStatus.WORKER_ERROR) {
        return this.contents.cannotFetchUrl;
      } else if (page.lastJobStatus === EDocumentationJobStatus.PARSE_ERROR) {
        return this.contents.cannotParseDocumentationFromUrl;
      } else {
        return undefined;
      }
    },
    getPublishStatus(isPublished: boolean) {
      return isPublished ? this.contents.published : this.contents.notPublished;
    },
    getTypeLabel,
  },
};
</script>

<style lang="scss">
.documentation-api {
  margin-top: 3rem;
}

// Dirty workaround so tooltips don't get cropped
.documentation-api__pages {
  margin-bottom: var(--base-spacing);
  .mc-datatable__container {
    overflow: visible;
    .mc-data-table__body {
      overflow: visible;
    }
  }
}

.documentation-api__cell-move {
  padding-left: 1rem;
  cursor: grab;
  transition: all 0.5s ease-in;
}

.documentation-api__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.documentation-api__table-header {
  color: var(--color-table-header);
}

.documentation-api__page-source {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.documentation-api__page-source-status {
  display: flex;
  margin-left: 2px;
}

.documentation-api__status-pending-icon {
  width: 21px;
  padding: 2px;
  margin-left: 1px;
  color: var(--color-white);
  background-color: var(--color-warning);
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.documentation-api__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.documentation-api__icon-action {
  padding: 0.5rem;
}
</style>
