export default {
  syncWithSecretManager: (secretManager: string) =>
    `Synced with ${secretManager}`,
  pendingSecretManagerSync: (secretManager: string) =>
    `Pending ${secretManager} sync`,
  pendingSyncTooltipMessage: (secretManager: string) =>
    `Your Application is waiting to be synchronized with ${secretManager}.`,
  plannedSyncTooltipMessage: (syncDate: string) =>
    `Synchronization planned for ${syncDate}.`,
  lastUpdateOn: (date: string) => `Last updated on ${date}`,
  deactivatedSynchronizationTooltipMessage: (secretManager: string) =>
    `Synchronization to ${secretManager} is temporarily deactivated.`,
};
