import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "publishing-api"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "publishing-api__incomplete-conditions" }
const _hoisted_4 = { class: "publishing-api__zones" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PublicationZoneCard = _resolveComponent("PublicationZoneCard")!

  return (!$options.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (Object.keys($options.routesByZone).length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h3", null, _toDisplayString($data.contents.youAreNotReadyToPublish), 1),
              _createElementVNode("p", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString($data.contents.yourApiCannotBePublished), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("h3", null, _toDisplayString($data.contents.header), 1),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.zoneIdsSortedByName, (zoneId) => {
                  return (_openBlock(), _createBlock(_component_PublicationZoneCard, {
                    key: zoneId,
                    class: "publishing-api__publication-zone-card",
                    zone: $options.zones[zoneId],
                    routes: $options.routesByZone[zoneId],
                    api: $options.currentApi
                  }, null, 8, ["zone", "routes", "api"]))
                }), 128))
              ])
            ], 64))
      ]))
    : _createCommentVNode("", true)
}