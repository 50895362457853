module.exports = {
  warning: "Be aware that this action cannot be undone",
  prompt: (applicationName) =>
    `If you really want to proceed, type <b>${applicationName}</b>, then confirm.`,
  cancel: "Cancel",
  confirm: "Confirm",
  modalTitle: (applicationName) => `You are about to delete ${applicationName}`,
  deleteApplicationLabel: "Type your application name",
  credentialsWarningTitle: "Secrets will be deleted",
  credentialsWarningMessage:
    "All your Secrets, including API Keys and OAuth ClientIDs, will be fully deleted (after a safety deactivation period for ClientIDs). If you use them elsewhere than this application, you will be impacted.",
  credentialsSecretAutomaticDeletionWarningMessage:
    "All your API Keys will be deleted from your Vault namespace: ",
  credentialsSecretManuelDeletionWarningMessage:
    "Vault Synchronization is temporarily switched off. You will have to clean your Vault namespace by yourself: ",

  deletionApplicationToastNotificationTitle: "Deletion application",
  deletionApplicationToastNotificationMessage:
    "Your application has been deleted",
  deletionApplicationToastNotificationWarningMessage:
    "Your application has been deleted but your keys have not been deleted from your Vault namespace due to Vault connection. Please do it manually.",
};
