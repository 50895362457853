<template>
  <div class="create-routing-policy">
    <template v-if="isEditing">
      <div class="create-routing-policy__conditions">
        <Conditions
          :value="form.conditions"
          :errors="form.errors"
          :routeEntryPoints="routeEntryPoints"
          @input="form.conditions = $event"
        />
      </div>
      <div class="create-routing-policy__target-url">
        <DvpField :errorMessage="urlValidationError">
          <DvpTextInput
            v-model="url"
            class="create-routing-policy__target-url-text-input"
            reference="inputTargetUrl"
            placeholder="Target URL"
            :isInvalid="urlValidationError != undefined"
          />
        </DvpField>
      </div>
      <div class="create-routing-policy__actions">
        <IconButton
          class="create-routing-policy__action-cancel"
          mozaicIconName="ControlCircleCross24"
          color="var(--color-danger)"
          @click="cancel"
        />
        <IconButton
          data-cy="save-dynamic-routing"
          :disabled="form.errors.length > 0 || urlValidationError != undefined"
          mozaicIconName="NotificationCircleAvailable24"
          color="var(--color-primary)"
          @click="create"
        />
      </div>
    </template>
    <div v-else class="create-routing-policy__add-new-dynamic-route-container">
      <DvpButton
        v-if="!isDisabled"
        class="create-routing-policy__add-new-dynamic-route"
        theme="bordered"
        :label="contents.addAnotherDynamicRoute"
        icon="ControlCircleMore24"
        size="s"
        @click="edit"
      />
    </div>
  </div>
</template>

<script lang="ts">
import DvpButton from "@/commons/components/Action/DvpButton.vue";
import IconButton from "@/commons/components/IconButton.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import DvpTextInput from "@/commons/components/form/DvpTextInput.vue";

import Conditions from "./Conditions.vue";

import { debounce } from "@/commons/libs/utils/debouncer";

import { validateRouteTargetUrl } from "@/commons/services/api/api-service";
import { RoutingPolicyForm } from "@/manager/domain/forms/routing-policy-form";

import contents from "@/manager/contents/create-routing-policy";

export default {
  components: { Conditions, DvpButton, DvpTextInput, IconButton, DvpField },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    routeEntryPoints: {
      type: Array,
      required: true,
    },
  },
  emits: ["create", "close", "edit"],
  data() {
    return {
      contents,
      form: RoutingPolicyForm.create(),
      urlValidationError: undefined,
    };
  },
  computed: {
    url: {
      get(): string {
        return this.form.url;
      },
      set(newUrl: string) {
        this.form.url = newUrl;
        this.debouncedValidateTargetUrl();
      },
    },
  },
  watch: {
    async isDisabled(isDisabled) {
      if (isDisabled) {
        this.form.clear();
        this.urlValidationError = undefined;
      }
    },
  },
  created() {
    this.debouncedValidateTargetUrl = debounce(this.validateTargetUrl);
  },
  mounted() {
    this.form.clear();
  },
  methods: {
    async create() {
      await this.form.validate();
      await this.validateTargetUrl();

      if (!this.form.errors.length && this.urlValidationError == undefined) {
        const { id, conditions, url } = this.form.data();
        this.$emit("create", { id, conditions, url });
        await this.form.clear();
      }
      this.$emit("close");
    },
    async cancel() {
      this.$emit("close");
      await this.form.clear();
      this.urlValidationError = undefined;
    },
    edit() {
      this.$emit("edit");
    },
    async validateTargetUrl() {
      const validation = await validateRouteTargetUrl(this.form.url);
      this.urlValidationError = validation.message;
    },
  },
};
</script>

<style>
.create-routing-policy {
  display: flex;
  margin-top: 32px;
}

.create-routing-policy__conditions {
  width: 60%;
}

.create-routing-policy__target-url {
  width: 30%;
  padding-right: 1rem;
}

.create-routing-policy__actions {
  width: 8%;
  margin-top: 0.5rem;
}

.create-routing-policy__add-new-dynamic-route {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.create-routing-policy__add-new-dynamic-route-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.create-routing-policy__action-cancel {
  margin-right: 0.5rem;
}
</style>
