<script setup lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { computed, PropType } from "vue";

import IconTooltip from "@/commons/components/IconTooltip.vue";

import {
  localeDateFormats,
  toDateFormat,
  toLocaleDatetime,
} from "@/commons/utils/date-utils";

import {
  ApplicationGatewaySynchronization,
  EApplicationGatewaySynchronizationStatus,
} from "@/commons/domain/models/application-gateway-synchronization";

import contents from "@/dashboard/contents/gateway-synchronization-status";

const props = defineProps({
  applicationGatewaySynchronization: {
    type: Object as PropType<ApplicationGatewaySynchronization>,
    required: true,
  },
});

const syncStatus = computed((): EApplicationGatewaySynchronizationStatus => {
  return props.applicationGatewaySynchronization?.status;
});

const applicationIsSynchronized = computed(() => {
  return (
    syncStatus.value === EApplicationGatewaySynchronizationStatus.SYNCHRONIZED
  );
});

const lastUpdateOnLabel = computed(() => {
  return props.applicationGatewaySynchronization.lastUpdated != undefined
    ? contents.lastUpdateOn(
        toDateFormat(
          props.applicationGatewaySynchronization.lastUpdated,
          localeDateFormats.SHORT_VERBOSE_DATE,
        ),
      )
    : undefined;
});

const statusLabel = computed(() => {
  if (
    syncStatus.value === EApplicationGatewaySynchronizationStatus.SYNCHRONIZED
  ) {
    return contents.syncedWithKong;
  } else if (
    syncStatus.value ===
    EApplicationGatewaySynchronizationStatus.PENDING_SYNCHRONIZATION
  ) {
    return contents.pendingKongSync;
  } else {
    return undefined;
  }
});

const tooltipMessage = computed(() => {
  return applicationIsSynchronized.value
    ? undefined
    : getPendingSyncTooltipMessage();
});

const getPendingSyncTooltipMessage = () => {
  const nextSyncTimeDate = toLocaleDatetime(
    props.applicationGatewaySynchronization?.next,
  );

  return undefined != nextSyncTimeDate
    ? `${contents.pendingSyncTooltipMessage} ${contents.plannedSyncTooltipMessage(nextSyncTimeDate)}`
    : contents.pendingSyncTooltipMessage;
};

const badgeType = computed(() => {
  return applicationIsSynchronized.value ? "success" : "warning";
});
</script>

<template>
  <div class="gateway-synchronization-status">
    <div class="gateway-synchronization-status__badge">
      <IconTooltip
        v-if="tooltipMessage != undefined"
        :text="tooltipMessage"
        position="left"
      />
      <MBadge
        v-if="statusLabel"
        class="gateway-synchronization__badge"
        :type="badgeType"
      >
        {{ statusLabel }}
      </MBadge>
    </div>
    <span
      v-if="lastUpdateOnLabel != undefined"
      class="gateway-synchronization-status__last-update"
    >
      {{ lastUpdateOnLabel }}
    </span>
  </div>
</template>

<style lang="scss">
.gateway-synchronization-status {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.gateway-synchronization-status__badge {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.gateway-synchronization-status__last-update {
  @include set-text-xs;
  color: var(--color-text-minor);
}
</style>
