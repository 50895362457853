<template>
  <LayerModalForm class="add-documentation-first-step" @submit="next">
    <template #fields>
      <div class="layer-title">{{ contents.chooseDocType }}</div>
      <div class="add-documentation-first-step__doc-types">
        <div
          v-for="itemType in filteredDocumentationTypeItems"
          :key="itemType.value"
          :class="[
            'add-documentation-first-step__doc-type',
            'cursor-pointer',
            {
              'add-documentation-first-step__doc-type--active': isSelected(
                itemType.value,
              ),
            },
          ]"
          :data-cy-value="itemType.value"
          @click="onTypeChange(itemType)"
        >
          <MIcon :name="itemType.icon" color="var(--color-text-minor)" />
          <div class="add-documentation-type-button__label">
            {{ itemType.label }}
          </div>
        </div>
      </div>
    </template>
    <template #formFooter>
      <MButton
        theme="bordered-neutral"
        :label="contents.cancel"
        @click="$emit('onClose')"
      />
      <MButton
        data-cy="add-doc-fist-step-next"
        type="submit"
        :label="contents.next"
      />
    </template>
  </LayerModalForm>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { markRaw } from "vue";

import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";
import AddOrUpdateDocumentationSecondStep from "@/manager/views/ApiDetailDocumentation/AddOrUpdateDocumentationSecondStep.vue";

import {
  DocumentationPage,
  EDocumentationType,
} from "@/commons/domain/models/documentation-page";

import {
  contents,
  documentationTypeItems,
} from "@/manager/contents/documentation-type-items";

export default {
  name: "AddDocumentationFirstStep",
  components: {
    MButton,
    MIcon,
    LayerModalForm,
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      selectedType: {
        value: undefined,
      },
    };
  },
  computed: {
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    hasSpec() {
      const documentationPagesList: DocumentationPage[] =
        this.$store.getters["managerDocumentationPagesList"];
      return documentationPagesList.some(
        (page) => page.type === EDocumentationType.REST_API_SPECIFICATIONS,
      );
    },
    filteredDocumentationTypeItems() {
      return documentationTypeItems.filter((typeItem) => {
        switch (typeItem.value) {
          case EDocumentationType.REST_API_SPECIFICATIONS:
            return !this.hasSpec;
          default:
            return true;
        }
      });
    },
  },
  mounted() {
    this.selectedType = this.filteredDocumentationTypeItems.find(
      (typeItem) => typeItem.value === EDocumentationType.MARKDOWN,
    );
  },
  methods: {
    isSelected(itemTypeValue) {
      return this.selectedType.value === itemTypeValue;
    },
    next() {
      this.$store.commit("openLayerModal", {
        title: `Add documentation 2/2`,
        component: markRaw(AddOrUpdateDocumentationSecondStep),
        props: {
          selectedType: this.selectedType,
        },
      });
    },
    onTypeChange(typeItem) {
      this.selectedType = typeItem;
      this.next();
    },
  },
};
</script>

<style>
.add-documentation-first-step__doc-types {
  display: flex;
  flex-wrap: wrap;
}

.add-documentation-first-step__doc-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  margin: 0 30px 30px 0;
  background-color: var(--color-background-secondary-light);
  border-radius: var(--m-border-radius-large);
}

.add-documentation-first-step__doc-type--active {
  background-color: var(--color-brand-primary-invert);
  border: 2px solid var(--color-stroke-dark);
}
</style>
