<template>
  <section class="layout-section" :class="sectionDynamicClasses">
    <div class="layout-section__content" :class="contentDynamicClasses">
      <slot />
    </div>
  </section>
</template>

<script lang="ts">
export default {
  props: {
    header: {
      type: Boolean,
      default: false,
    },
    fullWidthContent: {
      type: Boolean,
      default: false,
    },
    noVerticalPadding: {
      type: Boolean,
      default: false,
    },
    // If no background prop is provided, the background will be transparent
    // eslint-disable-next-line vue/require-default-prop
    background: {
      type: String,
      required: false,
      validator: (value) => ["primary", "secondary"].includes(value),
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    extendedBottomPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sectionDynamicClasses() {
      return {
        "layout-section__no-vertical-padding": this.noVerticalPadding,
        "layout-section__header": this.header,
        "layout-section__full-height-content": this.fullHeight,
        [this.backgroundClassName]: true,
        "layout-section__extended-bottom-padding": this.extendedBottomPadding,
      };
    },
    contentDynamicClasses() {
      return {
        "layout-section__full-width-content": this.fullWidthContent,
        "layout-section__fixed-width-content": !this.fullWidthContent,
      };
    },
    backgroundClassName() {
      return this.background
        ? `layout-section__background-${this.background}`
        : "";
    },
  },
};
</script>

<style lang="scss">
/* this element exists so we can have full width backgrounds with fixed width content */
.layout-section {
  display: flex; /* flex to prevent potential collapsing margin issues (h1 with margin at beginning of the slot for example) */
  justify-content: center;
  width: 100%;
  padding: 2.5rem 2rem;
}

.layout-section__header {
  padding-top: 3.5rem;
}

.layout-section__no-vertical-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.layout-section__background-primary {
  background-color: var(--color-background-primary);
}

.layout-section__background-secondary {
  background-color: var(--color-background-secondary-light);
}

.layout-section__content {
  flex: 1;
}

.layout-section__fixed-width-content {
  max-width: var(--layout-content-max-width);
}

.layout-section__full-height-content {
  display: flex;
  flex: 1;
}

.layout-section__extended-bottom-padding {
  padding-bottom: 7rem;
}
</style>
