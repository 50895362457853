import isDate from "lodash-es/isDate";
import isObject from "lodash-es/isObject";

export function patchState(
  partial: { [key: string]: any },
  state: { [key: string]: any } = {},
  mergeObjects: boolean = true,
) {
  for (const key in partial) {
    if (!Object.prototype.hasOwnProperty.call(partial, key)) continue;
    if (key === "__proto__" || key === "constructor") continue;
    if (
      isObject(partial[key]) &&
      !isDate(partial[key]) &&
      !Array.isArray(partial[key])
    ) {
      state[key] = mergeObjects
        ? { ...patchState(partial[key], state[key]) }
        : { ...(partial[key] || {}) };
    } else {
      state[key] = partial[key];
    }
  }

  return state;
}
