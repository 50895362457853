<template>
  <div class="application-settings">
    <div class="application-settings__col">
      <CardContainer class="application-settings__card-container">
        <div class="application-settings__card-title">
          <h4>
            {{ contents.applicationSettings }}
          </h4>
          <ViewHelpLink
            :documentationAnchor="contents.updateAppViewHelpDocAnchor"
          />
        </div>

        <UpdateApp v-if="application" :application="application" />
      </CardContainer>
      <SecretSynchronizationCard
        v-if="userIsInternal"
        data-cy="secret-synchronization-settings"
        class="application-settings__card-container"
        :application="application"
      />
      <ApplicationSensitiveModifications
        class="application-settings__card-container"
        :application="application"
      />
    </div>
    <div class="application-settings__col">
      <AccessSettings />
      <DeclareIPAddressesCard class="application-settings__card-container" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import ViewHelpLink from "@/commons/components/UserDocumentationLinks/ViewHelpLink.vue";
import UpdateApp from "@/dashboard/components/UpdateApp.vue";
import AccessSettings from "@/dashboard/views/AppDetails/AccessSettings.vue";
import ApplicationSensitiveModifications from "@/dashboard/views/AppDetails/ApplicationSensitiveModifications.vue";
import DeclareIPAddressesCard from "@/dashboard/views/AppDetails/DeclareIPAddressesCard.vue";
import SecretSynchronizationCard from "@/dashboard/views/AppDetails/SecretSynchronizationCard.vue";

import { Application } from "@/commons/domain/models/application";

import contents from "@/dashboard/contents/application";

const store = useStore();
const router = useRouter();

const application = computed(
  (): Application => store.getters["currentApplication"],
);

const userHasWriteAccessOnCurrentApplication = computed(
  (): boolean => store.getters["userHasWriteAccessOnCurrentApplication"],
);

const userIsInternal = computed(() => store.getters["userIsInternal"]);

onMounted(() => {
  if (!userHasWriteAccessOnCurrentApplication.value) {
    router.push({
      name: "application",
      params: { id: application.value.id },
    });
  }
});
</script>

<style lang="scss">
.application-settings {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
}

.application-settings__col {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 500px;
  gap: 2rem;
}

.application-settings__card-container {
  margin: 0; // margin is managed with flex gaps
}

.application-settings__card-title {
  display: flex;
  justify-content: space-between;

  margin-bottom: 1.5rem;
}
</style>
