import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "create-app__test-app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateGroup = _resolveComponent("CreateGroup")!
  const _component_MCheckbox = _resolveComponent("MCheckbox")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MTooltip = _resolveComponent("MTooltip")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_MLink = _resolveComponent("MLink")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_MAutocomplete = _resolveComponent("MAutocomplete")!
  const _component_GroupAutocompleteWrapper = _resolveComponent("GroupAutocompleteWrapper")!
  const _component_LinkManageGroups = _resolveComponent("LinkManageGroups")!
  const _component_NotificationWarningMCCGroup = _resolveComponent("NotificationWarningMCCGroup")!
  const _component_SearchTimezoneAutocompleteWrapper = _resolveComponent("SearchTimezoneAutocompleteWrapper")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (!$data.isLoading && $options.currentUserGroupsList.length === 0)
    ? (_openBlock(), _createBlock(_component_CreateGroup, {
        key: 0,
        class: "create-app__create-group"
      }))
    : (!$data.isLoading)
      ? (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
          key: 1,
          class: "create-app",
          confirmBtnLabel: $data.contents.createButtonLabel,
          confirmBtnTheme: "solid",
          disabledConfirmBtn: $data.form.errors.length > 0 || $options.isSaving,
          cancelBtnIsHidden: "",
          documentationAnchor: $data.contents.viewHelpDocumentationAnchor,
          onSubmit: $options.submit,
          onClose: $options.close
        }, {
          default: _withCtx(() => [
            ($options.userIsInternal)
              ? (_openBlock(), _createBlock(_component_DvpField, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_MCheckbox, {
                        id: "create-app__to-test",
                        modelValue: $data.form.forTesting,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.forTesting) = $event)),
                        label: $data.contents.testApplication,
                        disabled: $data.isLoading
                      }, null, 8, ["modelValue", "label", "disabled"]),
                      _createVNode(_component_MTooltip, {
                        id: "create-app__tooltip-test-app",
                        label: $data.contents.applicationForTestingTooltip,
                        position: "bottom"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MIcon, {
                            class: "create-app__test-app-icon",
                            name: "NotificationQuestion24"
                          })
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_DvpField, {
              label: $data.contents.applicationName,
              required: "",
              isInvalid: $options.isInvalidName(),
              errorMessage: $data.form.firstError('name')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MTextInput, {
                  modelValue: $options.appName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.appName) = $event)),
                  placeholder: $data.contents.applicationName,
                  isInvalid: $options.isInvalidName()
                }, null, 8, ["modelValue", "placeholder", "isInvalid"])
              ]),
              _: 1
            }, 8, ["label", "isInvalid", "errorMessage"]),
            ($data.appWithSameName !== undefined)
              ? (_openBlock(), _createBlock(_component_MNotification, {
                  key: 1,
                  title: $data.contents.appWithSameNameWarningTitle,
                  type: "warning"
                }, {
                  footer: _withCtx(() => [
                    _createVNode(_component_MLink, {
                      size: "s",
                      href: 
              $options.convertRouteToHref({
                name: 'application',
                params: {
                  id: $data.appWithSameName.id,
                },
              })
            
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($data.contents.goToApplicationLinkTitle), 1)
                      ]),
                      _: 1
                    }, 8, ["href"])
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.contents.appWithSameNameWarningMessage($data.appWithSameName.name)) + " ", 1)
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true),
            _createVNode(_component_DvpField, {
              "data-cy": "create-app__app-type",
              label: $data.contents.applicationType,
              required: "",
              isInvalid: $options.isInvalidType(),
              errorMessage: $data.form.firstError('type')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MSelect, {
                  modelValue: $data.form.type,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.type) = $event)),
                  placeholder: $data.contents.applicationTypeLabel,
                  options: $options.typeOptions
                }, null, 8, ["modelValue", "placeholder", "options"])
              ]),
              _: 1
            }, 8, ["label", "isInvalid", "errorMessage"]),
            _createVNode(_component_DvpField, {
              label: $data.contents.description,
              required: "",
              isInvalid: $options.isInvalidDescription(),
              errorMessage: $data.form.firstError('description')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MTextArea, {
                  modelValue: $data.form.description,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.description) = $event)),
                  placeholder: $data.contents.applicationDescLabel
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "isInvalid", "errorMessage"]),
            ($options.userIsInternal && !$data.form.forTesting)
              ? (_openBlock(), _createBlock(_component_DvpField, {
                  key: 2,
                  label: $data.contents.relatedProduct,
                  required: "",
                  errorMessage: $data.form.firstError('productId')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MAutocomplete, {
                      modelValue: $data.form.productId,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.form.productId) = $event)),
                      items: $data.productsList,
                      filter: false,
                      dataValueExpr: "id",
                      dataTextExpr: "name",
                      disabled: $data.isLoading,
                      placeholder: $data.contents.applicationProductPlaceholder,
                      "onUpdate:input": $options.debouncedSearchProduct
                    }, null, 8, ["modelValue", "items", "disabled", "placeholder", "onUpdate:input"])
                  ]),
                  _: 1
                }, 8, ["label", "errorMessage"]))
              : _createCommentVNode("", true),
            _createVNode(_component_DvpField, {
              label: $data.contents.managerGroup,
              required: "",
              errorMessage: $data.form.firstError('groupId')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_GroupAutocompleteWrapper, {
                  modelValue: $data.form.groupId,
                  "onUpdate:modelValue": [
                    _cache[5] || (_cache[5] = ($event: any) => (($data.form.groupId) = $event)),
                    $options.onGroupSelection
                  ],
                  items: $data.userGroups,
                  dataValueExpr: "id",
                  dataTextExpr: "name",
                  filter: false,
                  disabled: $data.isLoading,
                  placeholder: $data.contents.addManagerGroupPlaceholder,
                  "onUpdate:input": $options.debouncedSearchUserGroups
                }, null, 8, ["modelValue", "items", "disabled", "placeholder", "onUpdate:input", "onUpdate:modelValue"]),
                _createVNode(_component_LinkManageGroups, { class: "create-app__link-groups" })
              ]),
              _: 1
            }, 8, ["label", "errorMessage"]),
            ($data.displayMCCWarning)
              ? (_openBlock(), _createBlock(_component_NotificationWarningMCCGroup, { key: 3 }))
              : _createCommentVNode("", true),
            ($options.timeZonesFeatureIsEnabled)
              ? (_openBlock(), _createBlock(_component_DvpField, {
                  key: 4,
                  label: $data.contents.timezoneLabel,
                  helpText: $data.contents.timezoneSubLabel,
                  required: "",
                  errorMessage: $data.form.firstError('timeZone')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SearchTimezoneAutocompleteWrapper, {
                      modelValue: $data.form.timeZone,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.form.timeZone) = $event)),
                      "data-cy": "search-timezone",
                      placeholder: $data.contents.timezonePlaceholder
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label", "helpText", "errorMessage"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["confirmBtnLabel", "disabledConfirmBtn", "documentationAnchor", "onSubmit", "onClose"]))
      : _createCommentVNode("", true)
}