import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "organization__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DvpTextInput = _resolveComponent("DvpTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_DvpAutocomplete = _resolveComponent("DvpAutocomplete")!
  const _component_DvpButton = _resolveComponent("DvpButton")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (_openBlock(), _createBlock(_component_CardContainer, { class: "organization" }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "organization__form",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($options.submit && $options.submit(...args)), ["prevent"]))
      }, [
        _createElementVNode("h4", null, _toDisplayString($data.contents.title), 1),
        ($options.platformDescriptionTag)
          ? (_openBlock(), _createBlock(_component_DvpField, {
              key: 0,
              "data-cy": "api-platform",
              label: $data.contents.platformLabel,
              helpText: $data.contents.platformHelpText
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DvpTextInput, {
                  modelValue: $options.platformDescriptionTag,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.platformDescriptionTag) = $event)),
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label", "helpText"]))
          : _createCommentVNode("", true),
        _createVNode(_component_DvpField, {
          "data-cy": "api-business-units",
          label: $data.contents.businessUnit,
          tooltip: $data.contents.businessUnitsHelpText,
          error: $data.form.firstError('businessUnitTags')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DvpAutocomplete, {
              modelValue: $data.form.businessUnitTagsIds,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.businessUnitTagsIds) = $event)),
              items: $options.buTagsList,
              placeholder: $data.contents.businessUnitsPlaceholder,
              dataTextExpr: "label",
              dataValueExpr: "id",
              multiple: true,
              tagList: ""
            }, null, 8, ["modelValue", "items", "placeholder"])
          ]),
          _: 1
        }, 8, ["label", "tooltip", "error"])
      ], 32),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DvpButton, {
          type: "button",
          theme: "bordered-neutral",
          label: $data.contents.cancel,
          disabled: $data.form.pristine,
          onClick: $options.cancel
        }, null, 8, ["label", "disabled", "onClick"]),
        _createVNode(_component_DvpButton, {
          label: $data.contents.apply,
          disabled: !!$data.form.errors.length || $data.form.pristine,
          type: "submit",
          onClick: $options.submit
        }, null, 8, ["label", "disabled", "onClick"])
      ])
    ]),
    _: 1
  }))
}