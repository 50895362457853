import { decode } from "html-entities";

export function decodeHTML(content) {
  return decode(content);
}

export function escapeQuotes(content) {
  content = content.replace(/\\/g, "\\\\");
  return content.replace(/&quot;/gi, "\\&quot;");
}

export function decodeJsonEntities(jsonObj: any) {
  const { stringify, parse } = JSON;
  const jsonString = decodeHTML(escapeQuotes(stringify(jsonObj)));
  return parse(jsonString);
}

export function formatJson(content: string) {
  const { stringify, parse } = JSON;
  return stringify(parse(content), null, 2);
}

export function decodeAndFormat(content: string): string {
  try {
    return decodeHTML(formatJson(content));
  } catch (error) {
    return decodeHTML(content);
  }
}
