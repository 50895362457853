import { IsNotEmpty, MaxLength } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class EmailForm extends FormData {
  @IsNotEmpty()
  @MaxLength(30)
  subject: string = "";

  @IsNotEmpty()
  @MaxLength(500)
  body: string = "";

  $clear() {
    this.subject = "";
    this.body = "";
  }

  static create() {
    return FormFactory.createForm(new EmailForm());
  }
}
