<template>
  <CardContainer class="settings-form">
    <form
      autocomplete="on"
      class="settings-form__wrapper"
      @submit.prevent="submit"
    >
      <h4>{{ contents.settingsLabel(apiRestTypeLabel) }}</h4>

      <DvpField
        data-cy="api-product"
        :label="contents.relatedProduct"
        :errorMessage="form.firstError('productId')"
        :helpText="contents.relatedProductHelpText"
      >
        <SearchProductAutocompleteWrapper
          v-model="form.productId"
          :disabled="isLoading"
        />
      </DvpField>

      <!-- form.defaultError shows error even if this field was not touched, used temporarily -->
      <DvpField
        data-cy="api-description"
        :label="contents.description"
        :errorMessage="form.defaultError('description')"
      >
        <DvpTextArea
          v-model="form.description"
          class="group-edition-modal__description-textarea"
          :isInvalid="form.defaultError('description') != undefined"
          rows="5"
        />
      </DvpField>
    </form>
    <div class="settings-form__actions">
      <DvpButton
        data-cy="cancel-api-settings"
        type="button"
        theme="bordered-neutral"
        :label="contents.cancel"
        :disabled="form.pristine || !dataWasUpdated"
        @click="cancel"
      />

      <DvpButton
        data-cy="submit-api-settings"
        type="submit"
        :label="contents.applyChanges"
        :disabled="!!form.errors.length || form.pristine || !dataWasUpdated"
        @click="submit"
      />
    </div>
  </CardContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref } from "vue";
import { useStore } from "vuex";

import DvpButton from "@/commons/components/Action/DvpButton.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import DvpTextArea from "@/commons/components/form/DvpTextArea.vue";
import SearchProductAutocompleteWrapper from "@/commons/components/form/SearchProductAutocompleteWrapper.vue";

import { getApiRestTypeLabel } from "@/commons/libs/utils/apiUtils";
import { formDataWasUpdated } from "@/commons/utils/form-utils";

import { Api } from "@/commons/domain/models/api";
import { ApiUpdateForm } from "@/manager/domain/forms/api-update-form";

import contents from "@/manager/contents/settings-form";

const props = defineProps({
  currentApi: {
    type: Object as PropType<Api>,
    required: true,
  },
});

const store = useStore();

const form = ApiUpdateForm.create();
let persistedSettings = ref(undefined);

onMounted(async () => {
  const currentApi: Api = props.currentApi;

  await form.init({
    productId: (currentApi.product && currentApi.product.id) || null,
    description: currentApi.description,
  });

  persistedSettings.value = form.data();
});

const dataWasUpdated = computed((): boolean => {
  return formDataWasUpdated(form.data(), persistedSettings.value);
});

const isLoading = computed((): boolean => {
  return store.getters["isLoading"];
});

const isSaving = computed((): boolean => {
  return store.getters["isSaving"];
});

const apiRestTypeLabel = computed((): string => {
  return getApiRestTypeLabel(props.currentApi.internal);
});

const cancel = async () => {
  await form.init(persistedSettings.value);
};

const submit = async () => {
  await form.validate();

  if (!form.errors.length && dataWasUpdated && !isSaving.value) {
    persistedSettings.value = form.data();

    await store.dispatch("updateApiSettings", getUpdateApiPayload());
  }
};

const getUpdateApiPayload = () => {
  const { description, productId } = form.data();

  return {
    description:
      props.currentApi.description !== description ? description : undefined,
    productId:
      props.currentApi.product?.id !== productId ? productId : undefined,
  };
};
</script>

<style>
.settings-form {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  height: 100%;
}

.settings-form__wrapper {
  display: flex;
  flex-direction: column;

  gap: var(--base-spacing);
}

.settings-form__actions {
  display: flex;

  justify-content: flex-end;

  gap: 1rem;
}
</style>
