<script setup lang="ts">
import { computed, PropType } from "vue";

import DvpTooltip from "@/commons/components/DvpTooltip.vue";
import IconButton from "@/commons/components/IconButton.vue";
import IconTooltip from "@/commons/components/IconTooltip.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";
import { cropText } from "@/commons/utils/contents-utils";

import { Contract } from "@/commons/domain/models/contract";

const props = defineProps({
  contract: {
    type: Object as PropType<Contract>,
    default: undefined,
  },
  showActionsButton: {
    type: Boolean,
    default: false,
  },
  openActionsMenu: {
    type: Function,
    default: () => {},
  },
});

const label = computed(() => {
  return apiLabel(props.contract?.api);
});

const hasRecommendedActions = computed(() => {
  return props.contract?.recommendedActions?.length > 0;
});

const recommendedActionsTooltipText = computed(() => {
  return hasRecommendedActions.value
    ? props.contract?.recommendedActions[0]
    : "";
});
</script>

<template>
  <div class="contract-card-header">
    <div class="contract-card-header__left-side">
      <DvpTooltip :text="label">
        <span class="contract-card-header__api-label">{{
          cropText(label, 65)
        }}</span>
      </DvpTooltip>
      <IconTooltip
        v-if="hasRecommendedActions"
        data-cy="subscription-actions-menu"
        :text="recommendedActionsTooltipText"
        iconColor="var(--color-status-warning)"
      />
    </div>
    <div class="contract-card-header__right-side">
      <IconButton
        v-if="showActionsButton"
        data-cy="subscription-actions-menu"
        mozaicIconName="DisplayOptions24"
        @click="() => openActionsMenu()"
      />
    </div>
  </div>
</template>

<style lang="scss">
.contract-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 2.5rem;
}

.contract-card-header__left-side {
  display: flex;
  align-items: center;

  gap: 0.25rem;
}

.contract-card-header__right-side {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.contract-card-header__api-label {
  font-weight: 700;
}
</style>
