import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "top-navbar" }
const _hoisted_2 = { class: "top-navbar__menu-wrapper" }
const _hoisted_3 = { class: "top-navbar__logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "top-navbar__menu" }
const _hoisted_7 = { class: "top-navbar__right-side" }
const _hoisted_8 = {
  key: 0,
  class: "top-navbar__settings-wrapper"
}
const _hoisted_9 = { class: "top-navbar__settings" }
const _hoisted_10 = {
  key: 0,
  class: "top-navbar__user-profile-header"
}
const _hoisted_11 = { class: "top-navbar__user-profile-title" }
const _hoisted_12 = {
  key: 0,
  class: "top-navbar__user-profile-subtitle"
}
const _hoisted_13 = {
  key: 1,
  class: "top-navbar__signin-block"
}
const _hoisted_14 = { class: "top-navbar__devportal-slogan" }
const _hoisted_15 = { class: "top-navbar__environment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_RequestManagerMenuLabel = _resolveComponent("RequestManagerMenuLabel")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_DeploymentSelector = _resolveComponent("DeploymentSelector")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouterLink, {
          to: { name: 'home' },
          title: $data.contents.homeTitle
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: $data.logo,
              class: "top-navbar__logo--default"
            }, null, 8, _hoisted_4),
            _createElementVNode("img", {
              src: $data.logoSmall,
              class: "top-navbar__logo--small"
            }, null, 8, _hoisted_5)
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.navigationMenus, (menu, index) => {
          return (_openBlock(), _createBlock(_component_DropdownMenu, _mergeProps({ ref_for: true }, menu, {
            key: `${$options.componentId}-menu-${index}`,
            isActive: menu.realm === _ctx.$route.meta.realm
          }), null, 16, ["isActive"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      ($options.userIsLogged)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_DropdownMenu, _mergeProps($options.moreMenu, {
                key: "more-information-menu",
                isActive: $options.moreMenu.realm === _ctx.$route.meta.realm,
                menuPadding: "0"
              }), null, 16, ["isActive"]),
              _createVNode(_component_RequestManagerMenuLabel),
              _createVNode(_component_DropdownMenu, _mergeProps($options.userProfileMenu, {
                key: "user-profile-menu",
                isActive: $options.userProfileMenu.realm === _ctx.$route.meta.realm,
                menuPadding: "0",
                onLogout: $options.logout
              }), {
                header: _withCtx(() => [
                  ($options.user)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, _toDisplayString($options.user.name), 1),
                        ($options.userIsInternal)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString($data.contents.adeo), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 16, ["isActive", "onLogout"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("span", _hoisted_14, _toDisplayString($data.contents.devportalSlogan), 1),
            ($options.isNotOnLoginPage)
              ? (_openBlock(), _createBlock(_component_MButton, {
                  key: 0,
                  class: "top-navbar__signin-btn",
                  label: $data.contents.buttonLoginLabel,
                  onClick: $options.goToLoginPage
                }, null, 8, ["label", "onClick"]))
              : _createCommentVNode("", true)
          ])),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_DeploymentSelector, {
          class: "top-navbar__env-selector",
          disabled: _ctx.$route.meta.crossEnv !== true
        }, null, 8, ["disabled"])
      ])
    ])
  ]))
}