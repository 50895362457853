import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "create-routing-policy" }
const _hoisted_2 = { class: "create-routing-policy__conditions" }
const _hoisted_3 = { class: "create-routing-policy__target-url" }
const _hoisted_4 = { class: "create-routing-policy__actions" }
const _hoisted_5 = {
  key: 1,
  class: "create-routing-policy__add-new-dynamic-route-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Conditions = _resolveComponent("Conditions")!
  const _component_DvpTextInput = _resolveComponent("DvpTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_DvpButton = _resolveComponent("DvpButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.isEditing)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Conditions, {
              value: $data.form.conditions,
              errors: $data.form.errors,
              routeEntryPoints: $props.routeEntryPoints,
              onInput: _cache[0] || (_cache[0] = ($event: any) => ($data.form.conditions = $event))
            }, null, 8, ["value", "errors", "routeEntryPoints"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_DvpField, { errorMessage: $data.urlValidationError }, {
              default: _withCtx(() => [
                _createVNode(_component_DvpTextInput, {
                  modelValue: $options.url,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.url) = $event)),
                  class: "create-routing-policy__target-url-text-input",
                  reference: "inputTargetUrl",
                  placeholder: "Target URL",
                  isInvalid: $data.urlValidationError != undefined
                }, null, 8, ["modelValue", "isInvalid"])
              ]),
              _: 1
            }, 8, ["errorMessage"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_IconButton, {
              class: "create-routing-policy__action-cancel",
              mozaicIconName: "ControlCircleCross24",
              color: "var(--color-danger)",
              onClick: $options.cancel
            }, null, 8, ["onClick"]),
            _createVNode(_component_IconButton, {
              "data-cy": "save-dynamic-routing",
              disabled: $data.form.errors.length > 0 || $data.urlValidationError != undefined,
              mozaicIconName: "NotificationCircleAvailable24",
              color: "var(--color-primary)",
              onClick: $options.create
            }, null, 8, ["disabled", "onClick"])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (!$props.isDisabled)
            ? (_openBlock(), _createBlock(_component_DvpButton, {
                key: 0,
                class: "create-routing-policy__add-new-dynamic-route",
                theme: "bordered",
                label: $data.contents.addAnotherDynamicRoute,
                icon: "ControlCircleMore24",
                size: "s",
                onClick: $options.edit
              }, null, 8, ["label", "onClick"]))
            : _createCommentVNode("", true)
        ]))
  ]))
}