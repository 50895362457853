import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_MField = _resolveComponent("MField")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "send-email-modal",
    confirmBtnTheme: "solid-primary",
    confirmBtnLabel: $data.contents.submit,
    disabledConfirmBtn: !!$data.form.errors.length,
    onSubmit: $options.onSubmit,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h5", null, _toDisplayString($data.contents.title($options.apiLabel)), 1),
      _createVNode(_component_MField, {
        id: "subject",
        class: "send-email-modal__subject-field",
        label: $data.contents.subjectLabel,
        requirementText: "required",
        isInvalid: $options.isInvalidSubject,
        errorMessage: $data.form.firstError('subject')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.subject,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.subject) = $event)),
            placeholder: $data.contents.subjectPlaceholder,
            isInvalid: $options.isInvalidSubject
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "isInvalid", "errorMessage"]),
      _createVNode(_component_MField, {
        id: "message",
        class: "send-email-modal__message-field",
        label: $data.contents.messageLabel,
        requirementText: "required",
        isInvalid: $options.isInvalidMessage,
        errorMessage: $data.form.firstError('body')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextArea, {
            modelValue: $data.form.body,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.body) = $event)),
            placeholder: $data.contents.messagePlaceholder,
            isInvalid: $options.isInvalidMessage,
            rows: 15
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "isInvalid", "errorMessage"])
    ]),
    _: 1
  }, 8, ["confirmBtnLabel", "disabledConfirmBtn", "onSubmit"]))
}