import { ArrayNotEmpty } from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

export class ApiUpdateOrganization extends FormData {
  @ArrayNotEmpty({
    message: "You must select at least one Business Unit",
  })
  businessUnitTagsIds: string[];

  $clear() {
    this.businessUnitTagsIds = [];
  }

  static create() {
    return FormFactory.createForm(new ApiUpdateOrganization());
  }
}
