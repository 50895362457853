<template>
  <CardContainer v-if="form" :disabled="isDisabled">
    <form
      class="update-or-remove-target-url"
      autocomplete="off"
      @submit.prevent
    >
      <div class="update-or-remove-target-url__header-bar">
        <div class="update-or-remove-target-url__header">
          <h4>{{ contents.header }} #{{ positionInList }}</h4>
        </div>
        <DvpButton
          v-if="!isEditing"
          data-cy="edit-route"
          theme="solid-neutral"
          :label="contents.buttonEditLabel"
          @click="edit"
        />
      </div>

      <DvpField
        :label="contents.targetUrl"
        :helpText="isEditing ? contents.targetUrlTooltip : undefined"
        :errorMessage="urlValidationError"
        :isInvalid="urlValidationError != undefined"
        :required="isEditing"
      >
        <DvpTextInput
          v-if="isEditing"
          v-model="url"
          data-cy="edit-target-url"
          :placeholder="contents.targetUrlPlaceholder"
          :isInvalid="urlValidationError != undefined"
        />
        <span v-else data-cy="target-url">{{ url }}</span>
      </DvpField>

      <DvpNotification
        v-if="isEditing"
        class="update-or-remove-target-url__alert-message-gateway"
        type="information"
      >
        {{ contents.infoAskAnotherGateway }}
      </DvpNotification>

      <DvpField
        data-cy="select-zones"
        :label="contents.gateways"
        :helpText="isEditing ? contents.gatewaysTooltip : undefined"
        :isInvalid="form.hasError('zoneIds')"
        :required="isEditing"
      >
        <SelectZones :form="form" :isDisabled="!isEditing" />
      </DvpField>

      <DynamicRouting
        data-cy="dynamic-routing"
        :routingPolicies="currentlyUpdatingRoutingPolicies"
        :routeEntryPoints="downstreamEntryPoints"
        :isDisabled="!isEditing"
        :isDisplayDynRoutingNotification="isDisplayDynRoutingNotification"
        @update="updateRoutingPolicies"
        @displayNotification="displayDynRoutingNotification"
      />

      <div v-if="!isDisabled" class="update-or-remove-target-url__actions">
        <template v-if="isEditing">
          <DvpTooltip
            :disabled="!existEnabledDownstreamRoutes"
            :text="contents.unpublishedTooltip"
          >
            <DvpButton
              theme="bordered-danger"
              :disabled="existEnabledDownstreamRoutes"
              :label="contents.buttonRemoveLabel"
              @click="remove"
            />
          </DvpTooltip>
          <DvpButton
            theme="bordered-neutral"
            :label="contents.buttonCancelLabel"
            @click="cancel"
          />
          <DvpButton
            data-cy="save-the-route"
            :label="contents.buttonUpdateLabel"
            @click="update"
          />
        </template>
      </div>
    </form>
  </CardContainer>
</template>

<script lang="ts">
import { markRaw } from "vue";

import DvpButton from "@/commons/components/Action/DvpButton.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import DvpTooltip from "@/commons/components/DvpTooltip.vue";
import DvpNotification from "@/commons/components/Indicator/DvpNotification.vue";
import MessageConfirmModal from "@/commons/components/Modal/MessageConfirmModal.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import DvpTextInput from "@/commons/components/form/DvpTextInput.vue";

import DynamicRouting from "./DynamicRouting.vue";
import SelectZones from "./SelectZones.vue";

import { debounce } from "@/commons/libs/utils/debouncer";

import { Dictionary } from "@/commons/domain/models/dictionary";
import { Route } from "@/commons/domain/models/route";
import { validateRouteTargetUrl } from "@/commons/services/api/api-service";

import { ERouteTypes } from "@/commons/store/types";

import contents from "@/manager/contents/update-or-remove-target-url";

export default {
  components: {
    DvpField,
    DvpTextInput,
    CardContainer,
    DvpNotification,
    DynamicRouting,
    SelectZones,
    DvpTooltip,
    DvpButton,
  },
  props: {
    formParent: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      default: null,
    },
    positionInList: {
      type: Number,
      default: 1,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents,
      isEditing: false,
      persistedFormData: undefined,
      currentUpstreamId: "",
      currentlyUpdatingRoutingPolicies: [],
      isDisplayDynRoutingNotification: false,
      urlValidationError: undefined,
    };
  },
  computed: {
    url: {
      get(): string {
        return this.form.url;
      },
      set(newUrl: string) {
        this.form.url = newUrl;
        this.debouncedValidateTargetUrl();
      },
    },
    routingPolicies() {
      return (
        this.currentRouteUpstream && this.currentRouteUpstream.routingPolicies
      );
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    routes(): Dictionary<Route> {
      return this.$store.getters["routes"];
    },
    currentRouteUpstream() {
      return Object.values(this.routes).find(
        (route: Route) => route.id === this.currentUpstreamId,
      );
    },
    downstreamEntryPoints() {
      const upstreamRoute = this.currentRouteUpstream;
      if (!upstreamRoute) {
        return [];
      }
      return Object.values(this.routes).filter(
        (route: Route) =>
          route.type === ERouteTypes.DOWNSTREAM &&
          upstreamRoute.zoneIds.includes(route.zoneIds[0]),
      );
    },
    existEnabledDownstreamRoutes(): boolean {
      return !this.currentRouteUpstream.isRemovable;
    },
  },
  watch: {
    routingPolicies: {
      handler(newRoutingPolicies) {
        this.currentlyUpdatingRoutingPolicies = newRoutingPolicies;
      },
      deep: true,
    },
  },
  created() {
    this.debouncedValidateTargetUrl = debounce(this.validateTargetUrl);
  },
  mounted() {
    this.currentUpstreamId = this.form.id;
    this.currentlyUpdatingRoutingPolicies = this.routingPolicies;
  },
  methods: {
    displayDynRoutingNotification() {
      this.isDisplayDynRoutingNotification = true;
    },
    async update() {
      this.isDisplayDynRoutingNotification = false;
      if (!this.isDisabled) {
        await this.form.validate();
        await this.formParent.validate();
        await this.validateTargetUrl();

        if (
          !this.form.errors.length &&
          !this.formParent.errors.length &&
          this.urlValidationError == undefined
        ) {
          const route = this.form.data();
          await this.$store.dispatch("updateRoute", {
            apiId: this.currentApi.id,
            routeId: route.id,
            ...route,
            routingPolicies: Object.values(
              this.currentlyUpdatingRoutingPolicies,
            ),
          });
          await this.$store.dispatch("availableZones", this.currentApi.id);
          this.persistedFormData = this.form.data();
          this.isEditing = false;
        }
      }
    },
    async cancel() {
      if (!this.isDisabled) {
        await this.form.init(this.persistedFormData);
        await this.formParent.validate();
        this.urlValidationError = undefined;
        this.currentlyUpdatingRoutingPolicies = this.routingPolicies;
        this.isEditing = false;
      }
    },
    async remove() {
      this.$store.commit("openModal", {
        title: this.contents.modalTitleConfirmRemove,
        component: markRaw(MessageConfirmModal),
        props: {
          title: this.contents.headerConfirmRemove,
          message: this.contents.unPublishRouteModalText,
        },
        listeners: {
          onSubmit: () => {
            this.$store.dispatch("deleteRoute", {
              apiId: this.currentApi.id,
              routeId:
                this.currentRouteUpstream && this.currentRouteUpstream.id,
            });
            this.$store.commit("closeModal");
          },
        },
      });
    },
    edit() {
      if (!this.isDisabled) {
        this.persistedFormData = this.form.data();
        this.urlValidationError = undefined;
        this.isEditing = true;
      }
    },
    updateRoutingPolicies(updatedRoutingPolicies) {
      this.currentlyUpdatingRoutingPolicies = updatedRoutingPolicies;
    },
    async validateTargetUrl() {
      const validation = await validateRouteTargetUrl(this.form.url);
      this.urlValidationError = validation.message;
    },
  },
};
</script>

<style lang="scss">
.update-or-remove-target-url {
  display: flex;
  flex-direction: column;

  gap: var(--base-spacing);
}

.update-or-remove-target-url__header-bar {
  display: flex;

  justify-content: space-between;
}

.update-or-remove-target-url__header {
  display: flex;
  align-items: center;

  gap: 1rem;
}

.update-or-remove-target-url__actions {
  display: flex;

  justify-content: flex-end;
  gap: 1rem;
}
</style>
