export default {
  businessUnit: "Business unit",
  platformLabel: "Platform/Community",
  title: "Organization",
  businessUnitsHelpText:
    "Indicate which BU will use your API. Multiple choice is possible.",
  platformHelpText: "Your digital platform or your community",
  apply: "Apply changes",
  cancel: "Cancel",
  businessUnitsPlaceholder: "Search for Business Unit",
};
