<template>
  <form
    autocomplete="false | random-string"
    class="update-app"
    @submit.prevent="submit"
  >
    <div class="update-app__fields">
      <div
        v-if="user.isInternal"
        data-cy="update-app-for-testing"
        class="update-app__test-application"
      >
        <MCheckbox
          id="testing-value"
          class="update-app__test-application-value"
          :modelValue="form.forTesting"
          :disabled="isLoading || unableSwitchForTesting"
          :label="contents.testApplication"
          @update:modelValue="changeForTesting"
        />
        <IconTooltip
          :text="forTestingTooltipText"
          iconName="NotificationQuestion24"
        />
      </div>

      <DvpField
        data-cy="update-app-name"
        class="update-app__application-name"
        :label="contents.applicationName"
        :errorMessage="form.firstError('name')"
      >
        <DvpTooltip
          class="update-app__application-name-tooltip"
          :text="contents.disabledNameTooltip"
          :disabled="!currentApplicationSecretSynchronizationEnabled"
        >
          <MTextInput
            v-model="form.name"
            :isInvalid="isInvalidApplicationName"
            :disabled="currentApplicationSecretSynchronizationEnabled"
          />
        </DvpTooltip>
      </DvpField>

      <DvpField
        v-if="form.type"
        data-cy="update-app-type"
        class="update-app__app-type"
        :label="contents.applicationType"
        :errorMessage="form.firstError('type')"
      >
        <MSelect
          id="update-app__app-type-select"
          v-model="form.type"
          :placeholder="contents.applicationTypeLabel"
          :options="typeOptions"
        />
      </DvpField>

      <DvpField
        data-cy="update-app-description"
        class="update-app__description"
        :label="contents.description"
        :errorMessage="form.firstError('description')"
      >
        <MTextArea
          v-model="form.description"
          :disabled="isLoading"
          :placeholder="contents.applicationDescLabel"
          :isInvalid="isInvalidDescription"
        />
      </DvpField>

      <DvpField
        v-if="user.isInternal && !form.forTesting"
        data-cy="update-app-product"
        class="update-app__product"
        :label="contents.relatedProduct"
        :errorMessage="form.firstError('product')"
        :helpText="contents.applicationProductTooltip"
      >
        <SearchProductAutocompleteWrapper
          v-model="form.productId"
          :disabled="isLoading"
        />
      </DvpField>

      <DvpField
        data-cy="update-app-time-zone"
        class="update-app__time-zone"
        :label="contents.timezoneLabel"
        :errorMessage="form.firstError('timeZone')"
        :helpText="contents.timezoneSubLabel"
      >
        <SearchTimezoneAutocompleteWrapper
          v-model="form.timeZone"
          :placeholder="contents.timezonePlaceholder"
        />
      </DvpField>
    </div>

    <div class="update-app__form-actions">
      <MButton
        data-cy="cancel-btn"
        type="button"
        theme="bordered-neutral"
        :label="contents.cancel"
        :disabled="isActionButtonDisabled()"
        @click="cancel"
      />
      <MButton
        data-cy="submit-btn"
        type="submit"
        :label="contents.updateButtonLabel"
        :disabled="isActionButtonDisabled()"
      />
    </div>
  </form>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MCheckbox from "@mozaic-ds/vue-3/src/components/checkbox/MCheckbox.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { markRaw, PropType } from "vue";

import DvpTooltip from "@/commons/components/DvpTooltip.vue";
import IconTooltip from "@/commons/components/IconTooltip.vue";
import MessageConfirmModal from "@/commons/components/Modal/MessageConfirmModal.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import SearchProductAutocompleteWrapper from "@/commons/components/form/SearchProductAutocompleteWrapper.vue";
import SearchTimezoneAutocompleteWrapper from "@/commons/components/form/SearchTimezoneAutocompleteWrapper.vue";

import { Application } from "@/commons/domain/models/application";
import { UpdateAppForm } from "@/dashboard/domain/forms/update-app-form";

import contents from "@/dashboard/contents/create-or-update-app";

export default {
  name: "UpdateApp",
  components: {
    SearchTimezoneAutocompleteWrapper,
    DvpTooltip,
    IconTooltip,
    DvpField,
    MButton,
    MCheckbox,
    MTextInput,
    MTextArea,
    MSelect,
    SearchProductAutocompleteWrapper,
  },
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
  },
  data() {
    return {
      contents,
      form: UpdateAppForm.create(),
      persistedSettings: undefined,
    };
  },
  computed: {
    isInvalidApplicationName() {
      return this.form.firstError("name") != null;
    },
    isInvalidDescription() {
      return this.form.firstError("description") != null;
    },
    typeOptions() {
      return Object.entries(this.contents.applicationTypes).map(
        ([value, label]) => ({
          value,
          text: label,
        }),
      );
    },
    user() {
      return this.$store.getters["user"];
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    secretSyncIsActivated(): boolean {
      return this.application?.secretSynchronization?.isEnabled;
    },
    unableSwitchForTesting(): boolean {
      return this.application.forTesting == false && this.secretSyncIsActivated;
    },
    forTestingTooltipText(): string {
      return this.unableSwitchForTesting
        ? contents.unableToUpdateAppForTesting
        : contents.applicationForTestingTooltip;
    },
    currentApplicationSecretSynchronizationEnabled(): boolean {
      return this.$store.getters[
        "currentApplicationSecretSynchronizationEnabled"
      ];
    },
    timeZonesFeatureIsEnabled(): boolean {
      return this.$store.getters["config/timeZonesFeatureIsEnabled"];
    },
  },
  watch: {
    application: {
      async handler() {
        await this.initForm();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.initForm();
  },
  methods: {
    isActionButtonDisabled() {
      return this.isLoading || this.isSaving || this.form.pristine;
    },
    async cancel() {
      await this.form.init(this.persistedSettings);
    },
    async submit() {
      await this.form.validate();

      if (!this.form.errors.length) {
        if (this.form.timeZone !== this.persistedSettings.timeZone) {
          this.openUpdateTimezoneWarningModal();
        } else {
          await this.updateApplication();
        }
      }
    },
    openUpdateTimezoneWarningModal() {
      this.$store.commit("openModal", {
        title: contents.updateTimezoneModalHeader,
        component: markRaw(MessageConfirmModal),
        props: {
          title: contents.updateTimezoneModalTitle,
          message: contents.updateTimezoneModalContent,
          confirmBtnLabel: contents.updateTimezoneConfirmBtnLabel,
        },
        listeners: {
          onSubmit: async () => {
            await this.updateApplication();
            this.$store.commit("closeModal");
          },
        },
      });
    },
    async updateApplication() {
      await this.$store.dispatch("dashboardUpdateApplication", {
        id: (this.application && this.application.id) || null,
        name: this.form.name,
        type: this.form.type,
        description: this.form.description,
        productId: this.form.productId,
        forTesting: this.form.forTesting,
        timeZone: this.form.timeZone,
      });
    },
    changeForTesting(forTestingNewValue: boolean) {
      const partial: any = {
        forTesting: forTestingNewValue,
      };

      if (partial.forTesting) {
        partial.product = null;
      }

      this.form.update(partial);
    },
    async initForm() {
      const { name, description, product, type, forTesting, timeZone } =
        this.application;

      const partial: any = {
        name,
        description,
        forTesting,
        type,
        userIsInternal: this.user.isInternal,
        productId: product?.id,
        timeZone,
        timeZonesFeatureIsEnabled: this.timeZonesFeatureIsEnabled,
      };

      await this.form.init(partial);

      this.persistedSettings = this.form.data();
    },
  },
};
</script>

<style lang="scss">
.update-app__fields {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.update-app__test-application {
  display: flex;
  column-gap: 0.5rem;
}

.update-app__form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
  column-gap: var(--base-button-margin);
}

// Workaround to display the tooltip without impact on field's width
.update-app__application-name {
  .dvp-tooltip {
    display: block;
  }

  &-tooltip {
    .mc-tooltip-container {
      display: block;
    }
  }
}
</style>
