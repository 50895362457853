<script setup lang="ts">
import MAutocomplete from "@mozaic-ds/vue-3/src/components/autocomplete/MAutocomplete.vue";
import { onMounted, ref, watch } from "vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";

import {
  getProductById,
  searchProduct,
} from "@/commons/services/product/product-service";

import contents from "@/commons/contents/search-product-autocomplete-wrapper";

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(["update:modelValue"]);
const debouncer = new Debouncer();
const productsList = ref([]);
const debouncedSearchProduct = (): void => {
  debouncer.debounce(async () => await searchProducts(), 200);
};
const searchProducts = async () => {
  productsList.value = await searchProduct(search.value ?? "");
};
const localValue = ref("");
const search = ref("");

onMounted(async () => {
  await searchProducts();
  await updateInputValues(props.modelValue);
});

watch(
  () => props.modelValue,
  async () => {
    await updateInputValues(props.modelValue);
  },
);

// newValue => productID
const updateInputValues = async (newProductId: string) => {
  if (newProductId != undefined && localValue.value !== newProductId) {
    const selectedProduct = await getProductById(newProductId);

    if (
      selectedProduct != undefined &&
      productsList.value.find((p) => p.id === selectedProduct.id) == undefined
    ) {
      productsList.value.push(selectedProduct);
      productsList.value.sort((a, b) => a.name.localeCompare(b.name));
    }

    localValue.value = selectedProduct?.id ?? "";
    search.value = selectedProduct?.name ?? "";
  }
};

const onUpdateModelValue = (updatedValue: string) => {
  localValue.value = updatedValue;
  emit("update:modelValue", updatedValue);
};

const onInputUpdate = async (value: string) => {
  search.value = value;
  debouncedSearchProduct();
};
</script>

<template>
  <MAutocomplete
    v-bind="$attrs"
    :modelValue="localValue"
    :items="productsList"
    :filter="false"
    :empty="productsList?.length === 0"
    dataValueExpr="id"
    dataTextExpr="name"
    :placeholder="contents.searchProductPlaceholder"
    @update:input="onInputUpdate"
    @update:model-value="onUpdateModelValue"
  />
</template>
