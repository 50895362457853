<template>
  <CardContainer class="organization">
    <form class="organization__form" @submit.prevent="submit">
      <h4>{{ contents.title }}</h4>
      <DvpField
        v-if="platformDescriptionTag"
        data-cy="api-platform"
        :label="contents.platformLabel"
        :helpText="contents.platformHelpText"
      >
        <DvpTextInput v-model="platformDescriptionTag" disabled />
      </DvpField>

      <DvpField
        data-cy="api-business-units"
        :label="contents.businessUnit"
        :tooltip="contents.businessUnitsHelpText"
        :error="form.firstError('businessUnitTags')"
      >
        <DvpAutocomplete
          v-model="form.businessUnitTagsIds"
          :items="buTagsList"
          :placeholder="contents.businessUnitsPlaceholder"
          dataTextExpr="label"
          dataValueExpr="id"
          :multiple="true"
          tagList
        />
      </DvpField>
    </form>

    <div class="organization__actions">
      <DvpButton
        type="button"
        theme="bordered-neutral"
        :label="contents.cancel"
        :disabled="form.pristine"
        @click="cancel"
      />
      <DvpButton
        :label="contents.apply"
        :disabled="!!form.errors.length || form.pristine"
        type="submit"
        @click="submit"
      />
    </div>
  </CardContainer>
</template>

<script lang="ts">
import DvpButton from "@/commons/components/Action/DvpButton.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import DvpAutocomplete from "@/commons/components/form/DvpAutocomplete.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import DvpTextInput from "@/commons/components/form/DvpTextInput.vue";

import { getOrderedBuTagsList } from "@/commons/utils/filter-utils";

import { Dictionary } from "@/commons/domain/models/dictionary";
import { Tag } from "@/commons/domain/models/tag";
import { ApiUpdateOrganization } from "@/manager/domain/forms/api-update-organization";

import { ETagType } from "@/commons/store/types";

import contents from "@/manager/contents/organization";

export default {
  components: {
    CardContainer,
    DvpButton,
    DvpTextInput,
    DvpField,
    DvpAutocomplete,
  },
  props: {
    currentApi: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
      form: ApiUpdateOrganization.create(),
      persistedOrganization: undefined,
    };
  },
  computed: {
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    currentApiBusinessUnits() {
      return this.$store.getters["currentApiBusinessUnits"];
    },
    platformDescriptionTag() {
      const platformTag: Tag = Object.values(
        this.currentApi.tags as Dictionary<Tag>,
      ).find((tag) => tag.type === ETagType.PLATFORM);
      return platformTag?.description;
    },
    buTagsList() {
      let businessUnits = getOrderedBuTagsList(
        this.$store.getters["buTagsList"],
      );

      return businessUnits.map(({ id, label, description }) => {
        return description
          ? { id, label: `${label} - ${description}` }
          : { id, label };
      });
    },
  },
  async mounted() {
    const currentApiBusinessUnits: Tag[] = this.currentApiBusinessUnits;

    await this.form.init({
      businessUnitTagsIds: currentApiBusinessUnits.map(({ id }) => id),
    });

    this.persistedOrganization = this.form.data();
  },
  methods: {
    async submit() {
      await this.form.validate();

      if (!this.form.errors.length && !this.isSaving) {
        const { businessUnitTagsIds } = this.form.data();

        this.persistedSettings = this.form.data();

        await this.$store.dispatch("updateApiOrganization", {
          buTagIds: businessUnitTagsIds,
        });
      }
    },
    async cancel() {
      await this.form.init(this.persistedOrganization);
    },
  },
};
</script>

<style>
.organization {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  height: 100%;
}
.organization__form {
  display: flex;
  flex-direction: column;

  gap: var(--base-spacing);
}

.organization__actions {
  display: flex;
  justify-content: flex-end;

  gap: 1rem;
}
</style>
