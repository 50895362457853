export default {
  enabledBadgeLabel: "Enabled",
  disabledBadgeLabel: "Disabled",

  title: (secretManager: string) => `Publish Secrets to ${secretManager}`,
  subtitle: (secretManager: string) =>
    `By activating this feature, your Application ${secretManager} namespace will be completed with new paths containing all your application's API Keys & OAuth Credentials.`,

  activateToggleLabel: "Activate",

  betaVersionLabel: "Beta version",
  betaVersionMessage:
    "This feature is being tested at scale with selected teams. As a Beta version, its Vault JSON output may vary before final release. Contact us before using it.",

  canNotActivateOnTestingApp:
    "You cannot activate this feature while this application is a test one.",
  disabledSecretSyncFeatureTooltipText: (secretManager: string) =>
    `${secretManager} feature is temporarily deactivated. Please retry later.`,

  shortcutToManageRights: (secretManager: string) =>
    `Shortcut to manage <strong>${secretManager} rights</strong> in MyCloudConsole`,
};
