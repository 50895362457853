import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "conditions" }
const _hoisted_2 = {
  key: 0,
  class: "conditions__errors"
}
const _hoisted_3 = {
  key: 1,
  class: "conditions__condition-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_DvpTextInput = _resolveComponent("DvpTextInput")!
  const _component_MDropdown = _resolveComponent("MDropdown")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_DvpButton = _resolveComponent("DvpButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.value, (condition) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${condition.id}-${condition.type}`,
        class: "conditions__condition-row"
      }, [
        _createVNode(_component_MSelect, {
          id: "conditions__condition-type-select",
          class: "conditions__condition-type-select",
          disabled: $props.isDisabled,
          options: $options.conditionTypeOptions,
          modelValue: condition.type,
          "onUpdate:modelValue": ($event: any) => ($options.updateConditionType(condition, $event))
        }, null, 8, ["disabled", "options", "modelValue", "onUpdate:modelValue"]),
        (condition.type === $data.ERoutingPolicyType.HEADERS)
          ? (_openBlock(), _createBlock(_component_DvpTextInput, {
              key: 0,
              placeholder: $options.getKeyPlaceholder(condition.type),
              disabled: $props.isDisabled,
              modelValue: condition.key,
              "onUpdate:modelValue": ($event: any) => (
          $options.updateCondition({
            ...condition,
            key: $event,
          })
        )
            }, null, 8, ["placeholder", "disabled", "modelValue", "onUpdate:modelValue"]))
          : _createCommentVNode("", true),
        (
          [$data.ERoutingPolicyType.PATH, $data.ERoutingPolicyType.HEADERS].includes(
            condition.type,
          )
        )
          ? (_openBlock(), _createBlock(_component_DvpTextInput, {
              key: 1,
              placeholder: $options.getValuePlaceholder(condition.type),
              disabled: $props.isDisabled,
              modelValue: condition.value,
              "onUpdate:modelValue": ($event: any) => (
          $options.updateCondition({
            ...condition,
            value: $event,
          })
        )
            }, null, 8, ["placeholder", "disabled", "modelValue", "onUpdate:modelValue"]))
          : _createCommentVNode("", true),
        (condition.type === $data.ERoutingPolicyType.HTTP_METHODS)
          ? (_openBlock(), _createBlock(_component_MDropdown, {
              key: 2,
              class: "conditions__http-methods-select",
              "data-cy": "condition-http-methods",
              modelValue: condition.value,
              placeholder: $options.getValuePlaceholder(condition.type),
              items: $options.httpMethodItems,
              multiple: "",
              disabled: $props.isDisabled,
              "onUpdate:modelValue": ($event: any) => ($options.updateCondition({ ...condition, value: $event }))
            }, null, 8, ["modelValue", "placeholder", "items", "disabled", "onUpdate:modelValue"]))
          : _createCommentVNode("", true),
        (!$props.isDisabled)
          ? (_openBlock(), _createBlock(_component_IconButton, {
              key: 3,
              class: "conditions__icon",
              mozaicIconName: "PublishTrashbin24",
              onClick: ($event: any) => ($options.removeCondition(condition.id))
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    ($options.formHasErrorOnConditions)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($props.errors[0].messages[0]), 1))
      : _createCommentVNode("", true),
    (!$props.isDisabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          ($data.isAddingNewCondition)
            ? (_openBlock(), _createBlock(_component_MSelect, {
                key: 0,
                id: "conditions__add-condition-type-select",
                class: "conditions__add-condition-type-select",
                disabled: $props.isDisabled,
                placeholder: $data.contents.selectCondition,
                options: $options.conditionTypeOptions,
                "onUpdate:modelValue": $options.addCondition
              }, null, 8, ["disabled", "placeholder", "options", "onUpdate:modelValue"]))
            : (_openBlock(), _createBlock(_component_DvpButton, {
                key: 1,
                class: "conditions__add-condition-button",
                theme: "bordered-neutral",
                label: $data.contents.combineWithAnotherCondition,
                icon: "ControlCircleMore24",
                size: "s",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.isAddingNewCondition = true))
              }, null, 8, ["label"]))
        ]))
      : _createCommentVNode("", true)
  ]))
}