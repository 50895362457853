<template>
  <LayerModalFormConfirm
    class="send-email-modal"
    confirmBtnTheme="solid-primary"
    :confirmBtnLabel="contents.submit"
    :disabledConfirmBtn="!!form.errors.length"
    @submit="onSubmit"
    @close="$emit('onClose')"
  >
    <h5>{{ contents.title(apiLabel) }}</h5>
    <MField
      id="subject"
      class="send-email-modal__subject-field"
      :label="contents.subjectLabel"
      requirementText="required"
      :isInvalid="isInvalidSubject"
      :errorMessage="form.firstError('subject')"
    >
      <MTextInput
        v-model="form.subject"
        :placeholder="contents.subjectPlaceholder"
        :isInvalid="isInvalidSubject"
      />
    </MField>

    <MField
      id="message"
      class="send-email-modal__message-field"
      :label="contents.messageLabel"
      requirementText="required"
      :isInvalid="isInvalidMessage"
      :errorMessage="form.firstError('body')"
    >
      <MTextArea
        v-model="form.body"
        :placeholder="contents.messagePlaceholder"
        :isInvalid="isInvalidMessage"
        :rows="15"
      />
    </MField>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MField from "@mozaic-ds/vue-3/src/components/field/MField.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { PropType } from "vue";

import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";
import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";

import { Api } from "@/commons/domain/models/api";
import { EmailForm } from "@/commons/forms/email-form";

import contents from "@/documentation/contents/send-email-modal";

export default {
  components: {
    LayerModalFormConfirm,
    MTextInput,
    MTextArea,
    MField,
  },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      form: EmailForm.create(),
      contents,
    };
  },
  computed: {
    apiLabel(): string {
      return apiLabel(this.api);
    },
    isInvalidSubject() {
      return this.form.firstError("subject") != null;
    },
    isInvalidMessage() {
      return this.form.firstError("body") != null;
    },
  },
  async mounted() {
    await this.form.init();
  },
  methods: {
    async onSubmit() {
      if (!this.form.errors.length) {
        const { subject, body } = this.form.data();

        await getApiRepository().notifyOwners(this.api.id, subject, body);

        this.$store.commit("postSuccessNotification", {
          title: this.contents.sendingSuccessNotification,
        });
        this.$emit("onClose");
      }
    },
  },
};
</script>

<style lang="scss">
.send-email-modal {
  display: flex;
  flex-direction: column;
}
</style>
