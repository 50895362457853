export default {
  subscriptionMenuTitle: "Subscription options",
  apiKeyMenuTitle: "API Key options",

  renewApiKey: "Renew",
  extendApiKey: "Extend",
  deleteApiKey: "Delete this API Key",
  unsubscribe: "Unsubscribe",
  recommended: "Recommended",
  apiDocumentation: "API Documentation",
  contactApiOwners: "Contact API owners",
  manageApiSettings: "Manage API settings",
};
