import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "create-target-url" }
const _hoisted_2 = { class: "create-target-url__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DvpNotification = _resolveComponent("DvpNotification")!
  const _component_DvpTextInput = _resolveComponent("DvpTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_SelectZones = _resolveComponent("SelectZones")!
  const _component_DynamicRouting = _resolveComponent("DynamicRouting")!
  const _component_DvpButton = _resolveComponent("DvpButton")!
  const _component_CardContainer = _resolveComponent("CardContainer")!
  const _component_ButtonAddRoute = _resolveComponent("ButtonAddRoute")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.showNoAvailableZonesNotification)
      ? (_openBlock(), _createBlock(_component_DvpNotification, {
          key: 0,
          type: "information"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($data.contents.noGatewaysAvailable), 1)
          ]),
          _: 1
        }))
      : ($options.showCreationForm)
        ? (_openBlock(), _createBlock(_component_CardContainer, { key: 1 }, {
            default: _withCtx(() => [
              _createElementVNode("form", {
                class: "create-target-url__container",
                autocomplete: "off",
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
              }, [
                _createElementVNode("h4", null, _toDisplayString($data.contents.header), 1),
                _createVNode(_component_DvpField, {
                  "data-cy": "create-url-endpoint",
                  label: $data.contents.targetUrl,
                  helpText: $data.contents.targetUrlTooltip,
                  errorMessage: $data.urlValidationError,
                  isInvalid: $data.urlValidationError != undefined,
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DvpTextInput, {
                      modelValue: $props.form.url,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => (($props.form.url) = $event)),
                        $options.onUrlChange
                      ],
                      placeholder: $data.contents.targetUrlPlaceholder,
                      isInvalid: $data.urlValidationError != undefined
                    }, null, 8, ["modelValue", "placeholder", "isInvalid", "onUpdate:modelValue"])
                  ]),
                  _: 1
                }, 8, ["label", "helpText", "errorMessage", "isInvalid"]),
                _createVNode(_component_DvpField, {
                  "data-cy": "select-zones",
                  label: $data.contents.gateways,
                  helpText: $data.contents.gatewaysTooltip,
                  isInvalid: $props.form.hasError('zoneIds'),
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SelectZones, { form: $props.form }, null, 8, ["form"])
                  ]),
                  _: 1
                }, 8, ["label", "helpText", "isInvalid"]),
                _createVNode(_component_DynamicRouting, {
                  routingPolicies: $data.routingPolicies,
                  onUpdate: $options.updateRoutingPolicies
                }, null, 8, ["routingPolicies", "onUpdate"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_DvpButton, {
                    theme: "bordered-neutral",
                    label: $data.contents.buttonCancelLabel,
                    onClick: $options.cancel
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_DvpButton, {
                    "data-cy": "save-the-route",
                    label: $data.contents.buttonSaveLabel,
                    onClick: $options.create
                  }, null, 8, ["label", "onClick"])
                ])
              ], 32)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ButtonAddRoute, {
            key: 2,
            onClick: $options.edit
          }, null, 8, ["onClick"]))
  ]))
}