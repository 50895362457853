export enum EApplicationGatewaySynchronizationStatus {
  SYNCHRONIZED = "SYNCHRONIZED",
  PENDING_SYNCHRONIZATION = "PENDING_SYNCHRONIZATION",
}

export interface ApplicationGatewaySynchronization {
  status: EApplicationGatewaySynchronizationStatus;
  next: string;
  lastUpdated: string;
}
