<script setup lang="ts">
import { markRaw, PropType } from "vue";
import { useStore } from "vuex";

import DeactivateSecretSyncConfirmation from "@/dashboard/views/AppDetails/DeactivateSecretSyncConfirmation.vue";
import FailedSecretSyncDeactivation from "@/dashboard/views/AppDetails/FailedSecretSyncDeactivation.vue";

import { Application } from "@/commons/domain/models/application";
import { disableSecretSynchronization } from "@/commons/services/application/application-secret-synchronization.service";

import contents from "@/dashboard/contents/deactivate-secret-synchronization-modal";

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
  secretManager: {
    type: String,
    required: true,
  },
  secretPath: {
    type: String,
    required: true,
  },
  secretUrl: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["onClose"]);

const store = useStore();

const submit = async () => {
  const result = await disableSecretSynchronization(props.application.id);

  if (result) {
    loadApplication();

    store.commit("postSuccessNotification", {
      title: contents.successToastTitle(props.secretManager),
      message: contents.successToastDescription(props.secretManager),
    });

    emit("onClose");
  } else {
    store.commit("openLayerModal", {
      title: contents.modalTitle,
      component: markRaw(FailedSecretSyncDeactivation),
      props: {
        applicationId: props.application.id,
        secretManager: props.secretManager,
        secretPath: props.secretPath,
      },
    });
  }
};

const loadApplication = async (): Promise<void> => {
  await store.dispatch("loadCurrentApplication", {
    appId: props.application.id,
  });
};
</script>

<template>
  <DeactivateSecretSyncConfirmation
    :secretPath="secretPath"
    :secretUrl="secretUrl"
    :secretManager="secretManager"
    :application="application"
    @submit="submit"
    @onClose="emit('onClose')"
  />
</template>
