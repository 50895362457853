import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "create-api-first-step"
}
const _hoisted_2 = {
  key: 0,
  class: "create-api-first-step__group-creation-notification"
}
const _hoisted_3 = { class: "create-api-first-step__form-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateGroup = _resolveComponent("CreateGroup")!
  const _component_DvpStepper = _resolveComponent("DvpStepper")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MAutocomplete = _resolveComponent("MAutocomplete")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_GroupAutocompleteWrapper = _resolveComponent("GroupAutocompleteWrapper")!
  const _component_LinkManageGroups = _resolveComponent("LinkManageGroups")!
  const _component_NotificationWarningMCCGroup = _resolveComponent("NotificationWarningMCCGroup")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (!$data.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($options.currentUserGroupsList.length === 0)
          ? (_openBlock(), _createBlock(_component_CreateGroup, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_DvpStepper, {
                steps: $options.stepperSteps,
                stepperDescription: $data.contents.title
              }, null, 8, ["steps", "stepperDescription"]),
              ($data.userHadNoGroupOnModalOpen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_MIcon, {
                      class: "create-api-first-step__group-creation-notification-icon",
                      name: "NotificationCircleAvailable24",
                      color: "var(--color-status-success)"
                    }),
                    _createElementVNode("span", null, _toDisplayString($data.contents.groupCreationSuccessNotification), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_LayerModalForm, {
                  class: "create-api-first-step__form",
                  "data-cy": "create-api-form",
                  onSubmit: $options.submit
                }, {
                  fields: _withCtx(() => [
                    _createVNode(_component_DvpField, {
                      class: "create-api-first-step__related-product-field",
                      required: "",
                      label: $data.contents.relatedProduct,
                      helpText: $data.contents.relatedProductHelpText,
                      errorMessage: $data.form.firstError('product'),
                      "data-cy": "related-product-field"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MAutocomplete, {
                          modelValue: $data.form.productId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.productId) = $event)),
                          items: $data.productsList,
                          filter: false,
                          dataValueExpr: "id",
                          dataTextExpr: "name",
                          disabled: $data.isLoading,
                          placeholder: $data.contents.relatedProductPlaceholder,
                          "onUpdate:input": $options.debouncedSearchProduct
                        }, null, 8, ["modelValue", "items", "disabled", "placeholder", "onUpdate:input"])
                      ]),
                      _: 1
                    }, 8, ["label", "helpText", "errorMessage"]),
                    _createVNode(_component_DvpField, {
                      class: "create-api-first-step__business-unit-field",
                      required: "",
                      label: $data.contents.businessUnit,
                      helpText: $data.contents.businessUnitHelpText,
                      errorMessage: $data.form.firstError('businessUnitTagIds'),
                      "data-cy": "business-unit-field"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MAutocomplete, {
                          modelValue: $data.form.businessUnitTagIds,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.businessUnitTagIds) = $event)),
                          items: $options.buTagsList,
                          dataValueExpr: "id",
                          dataTextExpr: "label",
                          disabled: $data.isLoading,
                          placeholder: $data.contents.businessUnitPlaceholder,
                          multiple: "",
                          tagList: ""
                        }, null, 8, ["modelValue", "items", "disabled", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label", "helpText", "errorMessage"]),
                    _createVNode(_component_DvpField, {
                      class: "create-api-first-step__user-group-field",
                      required: "",
                      label: $data.contents.managerGroup,
                      helpText: $data.contents.managerGroupHelpText,
                      errorMessage: $data.form.firstError('groupId'),
                      "data-cy": "user-group-field"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_GroupAutocompleteWrapper, {
                          modelValue: $data.form.groupId,
                          "onUpdate:modelValue": [
                            _cache[2] || (_cache[2] = ($event: any) => (($data.form.groupId) = $event)),
                            $options.onGroupSelection
                          ],
                          items: $data.userGroups,
                          dataValueExpr: "id",
                          dataTextExpr: "name",
                          filter: false,
                          placeholder: $data.contents.managerGroupPlaceholder,
                          "onUpdate:input": $options.debouncedSearchUserGroups
                        }, null, 8, ["modelValue", "items", "placeholder", "onUpdate:input", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "helpText", "errorMessage"]),
                    _createVNode(_component_LinkManageGroups, { class: "create-api-first-step__link-groups" }),
                    ($data.displayMCCWarning)
                      ? (_openBlock(), _createBlock(_component_NotificationWarningMCCGroup, { key: 0 }))
                      : _createCommentVNode("", true)
                  ]),
                  formFooter: _withCtx(() => [
                    _createVNode(_component_MButton, {
                      theme: "bordered-neutral",
                      label: $data.contents.cancel,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('onClose')))
                    }, null, 8, ["label"]),
                    _createVNode(_component_MButton, {
                      class: "create-api-first-step__submit-button",
                      type: "submit",
                      label: $data.contents.next,
                      disabled: !$options.canSubmit
                    }, null, 8, ["label", "disabled"])
                  ]),
                  _: 1
                }, 8, ["onSubmit"])
              ])
            ], 64))
      ]))
    : _createCommentVNode("", true)
}