<template>
  <div class="contracts-application">
    <NotificationForKongSynchronization
      v-if="displayKongInformation && pendingGatewaySynchronization"
    />
    <div class="application-contracts">
      <div class="contracts-application__filters">
        <ContractsFilters
          v-if="totalContractsCount > 0"
          :disabled="isLoading"
          class="contracts-application__filters-on-left"
        />

        <div class="contracts-application__filters-on-right">
          <SearchTextFilter
            v-if="totalContractsCount > 0"
            class="contracts-application__search-filter"
            :placeholder="contents.searchSubscribedApi"
            queryParamName="term"
            :queryParamValue="searchTerm"
            size="s"
          />
          <MButton
            v-if="userHasWriteAccessOnCurrentApplication"
            class="contracts-application__show-subscribable-apis-button"
            data-cy="show-subscribable-apis-button"
            size="s"
            :label="contents.showSubscribableApisButton"
            @click="openSubscribableApisModal"
          />
        </div>
      </div>

      <header v-if="totalContractsCount === 0">
        <h4>{{ contents.noApiTitle }}</h4>
        <p>{{ noApiGuideline }}</p>
      </header>

      <ContractsTabs v-else />
    </div>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { markRaw } from "vue";

import SearchTextFilter from "@/commons/components/Filters/SearchTextFilter.vue";
import NotificationForKongSynchronization from "@/dashboard/components/NotificationForKongSynchronization.vue";
import ContractsFilters from "@/dashboard/views/AppDetailsContracts/ContractsFilters.vue";
import ContractsTabs from "@/dashboard/views/AppDetailsContracts/ContractsTabs.vue";
import SubscribableApisModal from "@/dashboard/views/AppDetailsContracts/modal/SubscribableApisModal.vue";
import ContractDeleteTokenModal from "@/dashboard/views/ContractsList/modal/ContractDeleteTokenModal.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";
import { userHasWriteAccessOnApplication } from "@/commons/utils/application-utils";

import { Application } from "@/commons/domain/models/application";
import { EApplicationGatewaySynchronizationStatus } from "@/commons/domain/models/application-gateway-synchronization";
import { Contract } from "@/commons/domain/models/contract";
import { Token } from "@/commons/domain/models/token";
import { getContractById } from "@/commons/services/contract/contract.service";

import contents from "@/dashboard/contents/application";
import contentsOfDeleteTokenModal from "@/dashboard/contents/contract-delete-token-modal";
import contentsOfSubscribableApisModal from "@/dashboard/contents/subscribable-apis-modal";

export default {
  components: {
    SearchTextFilter,
    NotificationForKongSynchronization,
    MButton,
    ContractsFilters,
    ContractsTabs,
  },
  props: {
    contractId: {
      type: String,
      default: undefined,
    },
    tokenId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.getters["isLoadingProperty"](
          "apiKeyApplicationContracts",
        ) || this.isLoadingOAuthApplicationContract
      );
    },
    isLoadingOAuthApplicationContract(): boolean {
      return (
        this.$store.getters["isLoadingProperty"](
          "oauthCCFApplicationContracts",
        ) ||
        this.$store.getters["isLoadingProperty"]("oauthACFApplicationContracts")
      );
    },
    application(): Application {
      return this.$store.getters["currentApplication"];
    },
    userHasWriteAccessOnCurrentApplication() {
      return this.$store.getters["userHasWriteAccessOnCurrentApplication"];
    },
    pendingGatewaySynchronization(): boolean {
      return (
        this.application?.gatewaySynchronization?.status ===
        EApplicationGatewaySynchronizationStatus.PENDING_SYNCHRONIZATION
      );
    },
    frontendConfig() {
      return this.$store.getters["config/frontendConfig"];
    },
    displayKongInformation() {
      return this.frontendConfig.displayKongInformation;
    },
    noApiGuideline() {
      if (this.userHasWriteAccessOnCurrentApplication) {
        return this.contents.noApiReadWriteGuideline;
      }
      return this.contents.noApiReadOnlyGuideline;
    },
    totalContractsCount(): number {
      return this.application?.metrics?.contracts?.total;
    },
    searchTerm(): string {
      return this.$route.query.term || "";
    },
    openedForTokenDeletionViaDirectLink(): boolean {
      return this.tokenId != undefined && this.contractId != undefined;
    },
  },
  async mounted() {
    if (this.openedForTokenDeletionViaDirectLink) {
      await this.checkContractAndTokenExistenceForTokenDeletion();
    }
  },
  methods: {
    openSubscribableApisModal(): void {
      this.$store.commit("openLayerModal", {
        title: contentsOfSubscribableApisModal.modalTitle,
        component: markRaw(SubscribableApisModal),
        listeners: {
          onSubmit: () => {
            this.$store.commit("closeModal");
          },
        },
      });
    },
    /**
     * Handle token deletion via a direct link from an e-mail
     * The direct link is sent in case of renewal (two API keys on one contract)
     * So user should delete the old one
     */
    async checkContractAndTokenExistenceForTokenDeletion() {
      const foundContract = await getContractById(this.contractId);

      if (foundContract == undefined) {
        return;
      }

      const foundToken = Object.values(foundContract?.tokens).find(
        (token) => token.id === this.tokenId,
      );

      if (foundToken == undefined) {
        this.$store.commit("postErrorNotification", {
          title: contentsOfDeleteTokenModal.tokenNotFoundTitleNotification,
          message: contentsOfDeleteTokenModal.tokenNotFoundMessageNotification(
            this.tokenId,
          ),
        });
      } else {
        if (foundToken.allowedActions["token.delete"] != true) {
          this.$store.commit("postErrorNotification", {
            title: contentsOfDeleteTokenModal.notAllowedTitleNotification,
            message: this.getErrorNotificationMessage(foundContract),
          });
        } else {
          this.openDeleteTokenModal(foundContract, foundToken);
        }
      }
    },
    getErrorNotificationMessage(contract: Contract): string {
      if (!userHasWriteAccessOnApplication(this.application)) {
        return contentsOfDeleteTokenModal.noManagerRightsMessageNotification;
      } else if (Object.values(contract.tokens).length === 1) {
        return contentsOfDeleteTokenModal.lastTokenOnSubscriptionMessageNotification(
          apiLabel(contract.api),
        );
      } else {
        return contentsOfDeleteTokenModal.notAllowedMessageNotification;
      }
    },
    openDeleteTokenModal(contract: Contract, token: Token): void {
      this.$store.commit("openLayerModal", {
        title: contentsOfDeleteTokenModal.modalTitle,
        component: markRaw(ContractDeleteTokenModal),
        props: {
          token,
          contract,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.application-contracts {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.application-contracts__available-apis {
  flex: 1;
  min-width: 25rem;
  max-width: 40rem;
}

.application-contracts__pagination {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contracts-application__filters {
  display: flex;
  justify-content: flex-end;
}

.contracts-application__filters-on-left {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 1rem;
}

.contracts-application__filters-on-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contracts-application__tab-first {
  margin-left: 3rem;
}
</style>
