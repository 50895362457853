export default {
  modalTitle: "Deactivate Secrets publication",
  title: (secretManager: string) =>
    `You are about to delete your Application's Secrets from ${secretManager}`,
  subtitle: "Be aware that this action cannot be undone.",
  notificationTitle: (secretManager: string) =>
    `Application's Secrets will be deleted from ${secretManager}`,
  notificationDescription: (secretManager: string, environment: string) =>
    `All your Secrets will be deleted from your ${secretManager} namespace for the ${environment} environment and will not be usable anymore from your Application. You can impact your instance if your Application still need them to start.`,
  confirmationTitle: (appName: string) =>
    `If you really want to proceed, type **${appName}**, then confirm.`,
  confirmationLabel: "Type the application name",

  successToastTitle: (secretManager: string) =>
    `Deletion Secrets from ${secretManager}`,
  successToastDescription: (secretManager: string) =>
    `Your Secrets have been deleted from ${secretManager}.`,

  impossibleToCleanSecretNamespaceTitle: (secretManager: string) =>
    `Impossible to clean your ${secretManager} namespace`,

  impossibleToCleanSecretNamespaceDescription: (secretPath: string) =>
    `It seems DevPortal has no more the rights to write on your namespace ${secretPath}.`,

  solutions: (secretManager: string, userDocLink: string) =>
    "Three solutions for you:\n\n" +
    `* You already cleaned your ${secretManager} namespace by yourself: just confirm the deactivation.\n` +
    "* You will clean your namespace by yourself (do it now!): just confirm the deactivation.\n" +
    `* You reassign rights to DevPortal on your namespace (see docs + [link](${userDocLink})): cancel the process and retry it later.`,

  confirmBtnCleanedSecretNamespace: (secretManager: string) =>
    `I confirm my ${secretManager} is already cleaned`,

  userDocumentationAnchor: "#deactivate-vault-publication",
};
