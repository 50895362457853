export default {
  modalTitle: "Contact owners",
  title: (apiLabel: string) => `Contact owners of: ${apiLabel}`,
  subjectLabel: "Subject",
  subjectPlaceholder: "Specify your request",
  messageLabel: "Your message",
  messagePlaceholder: "Type your message here",
  submit: "Send your message",
  sendingSuccessNotification: "Message sent",
  contactOwnersErrorNotification: "No team found for this api",
};
